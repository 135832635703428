import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import Moment from 'react-moment';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceFloor from '../../services/serviceFloor';
import ServiceBooking from '../../services/serviceBooking';

export default function PanelPosBook() {

    const [token] = useRecoilState(tokenAtom);

    const [floors, setFloors] = useState([]);
    const [rooms, setRooms] = useState([]);

    const [submit, setSubmit] = useState(false);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();
    const floorService = new ServiceFloor();
    const bookingService = new ServiceBooking();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        email: Yup.string().required('This information is required').email('Please enter valid email address'),
        phone: Yup.string().matches(/^[0-9]{10}$/, { message: 'Please enter valid phone number' }).required('This information is required'),
        addr: Yup.string().required('This information is required'),
        sdate: Yup.string().required('This information is required'),
        edate: Yup.string().required('This information is required'),
    });

    const getOID = () => {
        return "ACK" + Math.floor(Date.now() / 1000);
    }

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            name: '',
            email: '',
            phone: '',
            addr: '',
            sdate: '',
            edate: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            let body = {
                bookid: getOID(),
                bookName: values.name,
                bookEmail: values.email,
                bookPhone: values.phone,
                bookAddr: values.addr,
                sdate: Moment(values.sdate).toISOString(),
                edate: Moment(values.edate).toISOString(),
                tenentId: "66190e8338d3999c7e8cfbbe",
                provider: "POS",
                status: "open",
            }
            console.log(body);
            // bookingService.setBooking(body, token).then((res) => {
            //     setSubmit(false);
            //     if (res.status) {
            //         toast.success("New booking added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            //         goBack();
            //     } else {
            //         toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            //     }
            // });
        }
    });

    const goBack = () => {
        navigate(`/home/pos`);
    }

    const toggleRoom = (room) => {
        let roomIndex = rooms.findIndex((a) => a.title === room.title);
        let tempList = [...rooms];
        if (roomIndex === -1) {
            tempList.push(room);
        } else {
            tempList.splice(roomIndex, 1);
        }
        setRooms(tempList);
    }

    const isRoomSelected = (title) => {
        return rooms.find((a) => a.title === title);
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {};
            var doc = await floorService.getAllFloor(body, token);
            setFloors(doc.data);
            setLoading(false);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);






























































    const [products, setProducts] = useState([]);
    const [items, setItems] = useState([]);
    const [docFiles, setDocFiles] = useState(null);
    const [isValid, setIsValid] = useState(false);

    // const productService = new ServiceProduct();
    // const orderService = new ServiceOrder();
    // const emailService = new ServiceEmail();



    const formVSchemaA = Yup.array().of(
        Yup.object().shape({
            name: Yup.string().required('This information is required'),
            qty: Yup.string().required('This information is required'),
            rate: Yup.string().required('This information is required'),
        })
    );

    // const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
    //     initialValues: {
    //         name: '',
    //         email: '',
    //         phone: '',
    //         addr: '',
    //         items: [],
    //     },
    //     enableReinitialze: true,
    //     validationSchema: formVSchema,
    //     onSubmit: values => {
    //         formVSchemaA.isValid(items).then(function (valid) {
    //             setIsValid(!valid);
    //             if (valid) {
    //                 // setSubmit(true);
    //                 // let body = {
    //                 //     "name": values.name,
    //                 //     "email": values.email,
    //                 //     "phone": values.phone,
    //                 //     "addr": values.addr,
    //                 //     "items": getItems(),
    //                 //     "status": "active"
    //                 // }
    //                 // orderService.addOrder(body, token).then((res) => {
    //                 //     setSubmit(false);
    //                 //     if (res.status) {
    //                 //         let bodyEmail = {
    //                 //             name: values.name,
    //                 //             email: values.email,
    //                 //             oid: res.data.OID
    //                 //         };
    //                 //         emailService.sendEmailA(bodyEmail, token).then((res) => {
    //                 //             toast.success("New order added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
    //                 //             goBack();
    //                 //         });
    //                 //     } else {
    //                 //         toast.error('API not pentrated please wait..!!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
    //                 //     }
    //                 // });
    //             }
    //         });
    //     },
    // });

    const getItems = () => {
        let tempItems = [...items];
        let mainItems = [];
        tempItems.forEach((selItem) => {
            mainItems.push({
                "pid": selItem.name,
                "pname": products.filter((prd) => prd._id === selItem.name)[0].name,
                "purl": products.filter((prd) => prd._id === selItem.name)[0].media.length !== 0 ? products.filter((prd) => prd._id === selItem.name)[0].media[0].fileurl : '',
                "rate": selItem.rate.toString(),
                "qty": selItem.qty.toString()
            });
        });
        return mainItems;
    }

    const getProductsOption = () => {
        var proFiles = products.map((d) => {
            return { value: d._id, label: d.name }
        });
        return proFiles;
    }



    const handleOnChange = (e, index) => {
        var { value, name } = e.target;
        let tempVal = [...items];
        switch (name) {
            case 'pname':
                tempVal[index].name = value;
                break;
            case 'pqty':
                tempVal[index].qty = value;
                break;
            case 'prate':
                tempVal[index].rate = value;
                break;
            default:
                break;
        }
        setItems(tempVal);
    }

    const addRow = () => {
        let val = [...items];
        val.push({ name: '', rate: 0, qty: 0 });
        setItems(val);
    }

    const delRow = (index) => {
        let val = [...items];
        val.splice(index, 1)
        setItems(val);
    }











































    return (
        <div className="w-full h-full p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all bookings</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Booking</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to booking.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Booking Information</h3>
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Booking person email address<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="email" onChange={handleChange} autoComplete="email" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Email address" value={values.email} />
                                                {(errors.email && touched.email) && <p className='text-xs text-red-400 mt-1'>{errors.email}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Booking person name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Full name" value={values.name} />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className="col-span-6 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Phone Number<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="phone" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Phone number" value={values.phone} />
                                                {(errors.phone && touched.phone) && <p className='text-xs text-red-400 mt-1'>{errors.phone}</p>}
                                            </div>

                                            <div className="col-span-6">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Physical address 1<sup className="text-red-600">*</sup></label>
                                                <textarea id="addr" onChange={handleChange} autoComplete="street-address" className="mt-1 p-2 block w-full h-20 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Street address" value={values.addr}></textarea>
                                                {(errors.addr && touched.addr) && <p className='text-xs text-red-400 mt-1'>{errors.addr}</p>}
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className="col-span-12">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Booking Details</h3>
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Check-In Date Time<sup className="text-red-600">*</sup></label>
                                                <input type="date" id="sdate" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Select date" value={values.sdate} />
                                                {(errors.sdate && touched.sdate) && <p className='text-xs text-red-400 mt-1'>{errors.sdate}</p>}
                                            </div>

                                            <div className="col-span-3">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Check-Out Date Time<sup className="text-red-600">*</sup></label>
                                                <input type="date" id="edate" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. Select date" value={values.edate} />
                                                {(errors.edate && touched.edate) && <p className='text-xs text-red-400 mt-1'>{errors.edate}</p>}
                                            </div>

                                            <div className="col-span-6"></div>

                                            <div className="col-span-12">
                                                {
                                                    floors.length !== 0 && floors.map((cuint) => {
                                                        return <div className='w-full grid grid-cols-12 gap-2 my-2'>
                                                            {
                                                                cuint.units.length !== 0 && cuint.units.map((unit, index) => {
                                                                    if (unit.type === 'Room' && unit.type !== 'active') {
                                                                        return < div className={`p-2 rounded col-span-1 border border-dashed border-gray-400 cursor-pointer hover:shadow-md ${isRoomSelected(unit.title) ? "bg-green-600" : "bg-white"} `} key={index} onClick={() => { toggleRoom(unit); }}>
                                                                            <div className='flex justify-between items-center px-1'>
                                                                                <div>
                                                                                    <p className='text-xl font-medium font-serif'>{unit.title}</p>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    }
                                                                })
                                                            }
                                                        </div>
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <div className="grid grid-cols-6 gap-6">
                                            <div className="col-span-6">
                                                <h3 className="text-lg font-medium leading-6 text-gray-900">Guest Details</h3>
                                            </div>

                                            <div className="col-span-4 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Customer name<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Full name" value={values.name} />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className="col-span-4 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Document Type<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Full name" value={values.name} />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className="col-span-4 sm:col-span-2">
                                                <label className="block text-xs font-medium text-gray-700 font-mono">Document Number<sup className="text-red-600">*</sup></label>
                                                <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Full name" value={values.name} />
                                                {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                            </div>

                                            <div className='col-span-6 grid grid-cols-6 gap-3'>
                                                <div className='col-span-6 my-2'>
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Product Image</label>
                                                    {
                                                        docFiles === null && <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" className="sr-only" onChange={(e) => setDocFiles(e.target.files[0])} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG up to 1MB
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        docFiles !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <i className="las la-link text-gray-400 text-lg"></i>
                                                                <span className="ml-2 flex-1 w-0 truncate">
                                                                    {docFiles.name}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono">
                                                                    <span>View</span>
                                                                </label>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" className="sr-only" onChange={(e) => setDocFiles(e.target.files[0])} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div> */}
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "ADD BOOKING"}
                        </button>
                    </div>
                </form >
            </div >
        </div >
    )
}
