import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useRecoilState } from 'recoil';
import { tokenAtom, paramsAtom, companyAtom } from '../../core/config/atoms';

import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { motion } from "framer-motion";

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceMenu from '../../services/serviceMenu';
import ServiceOrder from '../../services/serviceOrder';
import ServiceFloor from '../../services/serviceFloor';
import ServiceInvoice from '../../services/serviceInvoice';
import ServicePrinter from 'services/servicePrinter';

export default function PanelPosOrder() {

    const [token] = useRecoilState(tokenAtom);
    const [param, setParam] = useRecoilState(paramsAtom);
    const [company] = useRecoilState(companyAtom);

    const searchARef = useRef('');
    const searchBRef = useRef('');

    const [menus, setMenus] = useState([]);
    const [menusFilter, setMenusFilter] = useState([]);
    const [cats, setCats] = useState([]);
    const [orders, setOrders] = useState([]);
    const [ordItems, setOrdItems] = useState([]);
    const [selOrdIds, setSelOrdIds] = useState([]);

    const [table, setTable] = useState(undefined);
    const [invoice, setInvoice] = useState({});

    const [query, setQuery] = useState('');
    const [selCat, setSelCat] = useState('all');
    const [tab, setTab] = useState('order');
    const [terminal, setTerminal] = useState('order');
    const [selOrd, setSelOrd] = useState('');
    const [pmode, setPMode] = useState('cash');
    const [mode, setMode] = useState('idle');
    const [shortCode, setShortCode] = useState('-1');
    const [quantity, setQuantity] = useState('0');

    const [selItemIndex, setSelItemIndex] = useState(-1);

    const [submit, setSubmit] = useState(false);

    const navigate = useNavigate();
    const menuService = new ServiceMenu();
    const orderService = new ServiceOrder();
    const floorService = new ServiceFloor();
    const invoiceService = new ServiceInvoice();
    const printService = new ServicePrinter();

    const gotoBack = () => {
        setParam({});
        navigate(`/home/pos`);
    }

    const handleChangeForm = (target) => {
        switch (target) {
            case "itemSearch":
                const queryA = searchARef.current.value;
                let tempListA = menus.filter((item) => item.name.toLowerCase().includes(queryA));
                setMenusFilter(tempListA);
                break;
            case "itemCode":
                const queryB = searchBRef.current.value;
                let tempListB = menus.filter((item) => item.shortCode.toLowerCase().includes(queryB));
                setMenusFilter(tempListB);
                break;
        }
    }

    const sortTable = (filter) => {
        setSelCat(filter);
        if (filter === 'all') {
            setMenusFilter(menus);
        } else {
            let tempList = menus.filter((item) => item.category === filter);
            setMenusFilter(tempList);
        }
    }

    const addItem = (item) => {
        if (terminal === "order" || terminal === "update") {
            let isItem = ordItems.findIndex((a) => a.id === item._id);
            let tempList = [...ordItems];
            if (isItem === -1) {
                let ordItem = {
                    id: item._id,
                    name: item.name,
                    shortCode: item.shortCode,
                    category: item.category,
                    price: item.variant[0].price,
                    tax: item.variant[0].tax,
                    qty: 1,
                    status: 'none'
                };
                tempList.push(ordItem);
            } else {
                tempList[isItem].qty = tempList[isItem].qty + 1;
            }
            setOrdItems(tempList);
        }
    }

    const removeQty = (id) => {
        if (terminal === "order" || terminal === "update") {
            let isItem = ordItems.findIndex((a) => a.id === id);
            let tempList = [...ordItems];
            tempList[isItem].qty = tempList[isItem].qty - 1;
            setOrdItems(tempList);
        }
    }

    const removeItem = (id) => {
        if (terminal === "order" || terminal === "update") {
            let isItem = ordItems.findIndex((a) => a.id === id);
            let tempList = [...ordItems];
            tempList.splice(isItem, 1);
            setOrdItems(tempList);
        }
    }

    const addQty = (id) => {
        if (terminal === "order" || terminal === "update") {
            let isItem = ordItems.findIndex((a) => a.id === id);
            let tempList = [...ordItems];
            tempList[isItem].qty = tempList[isItem].qty + 1;
            setOrdItems(tempList);
        }
    }

    const getTotal = () => {
        let total = 0;
        ordItems.forEach((a) => {
            total = total + (a.price * a.qty);
        });
        return total;
    }

    const getTax = () => {
        let tax = 0;
        ordItems.forEach((a) => {
            tax = tax + ((a.price * a.qty) * (a.tax / 100));
        });
        return tax;
    }

    const getPType = (type) => {
        switch (type) {
            case 'takeaway':
                return "Take-Away Order";
            case 'delivery':
                return "Delivery Order";
            default:
                return "NA";
        }
    }

    const getOrderStatus = (sts) => {
        switch (sts) {
            case 'open':
            case 'running':
                return "Running";
            case 'billed':
                return 'Billed';
            default:
                return "NA";
        }
    }

    const getOID = () => {
        return "KOT" + Math.floor(Date.now() / 1000);
    }

    const placeOrder = () => {
        if (terminal === "order") {
            if (ordItems.length === 0) {
                toast.error('Please add an item.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                setSubmit(true);
                let body = {};
                if (param.type !== 'takeaway') {
                    body = {
                        ordid: getOID(),
                        ordRef: param.id,
                        ordRT: param.index,
                        items: ordItems,
                        total: getTotal(),
                        tax: getTax(),
                        time: moment().toISOString(),
                        isRoom: false,
                        tenentId: "66190e8338d3999c7e8cfbbe",
                        provider: "POS",
                        status: "open",
                    }
                } else {
                    body = {
                        ordid: getOID(),
                        ordRef: 'takeaway',
                        ordRT: 0,
                        items: ordItems,
                        total: getTotal(),
                        tax: getTax(),
                        time: moment().toISOString(),
                        isRoom: false,
                        tenentId: "66190e8338d3999c7e8cfbbe",
                        provider: "POS",
                        status: "open",
                    }
                }
                orderService.setOrderWithRef(body, token).then((res) => {
                    if (res.status) {
                        let payload = {
                            "name": company.tradNme,
                            "unit": table !== undefined ? table.title : getPType(),
                            "ordID": res.data.ordID,
                            "ordItems": res.data.ordItems,
                            "ordTime": res.data.ordTime,
                            "provider": res.data.provider
                        };
                        sendToPrint(payload, 'K');
                        toast.success("New order added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        setSubmit(false);
                        setOrdItems([]);
                        if (param.type === "takeaway") {
                            gotoBack();
                        } else {
                            loadOrder();
                        }
                    } else {
                        toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            }
        }
    }

    const sendToPrint = (payload, type) => {
        printService.getAllPrinterByType({ 'type': type }, token).then((res) => {
            if (res.status) {
                res.data.map((item) => {
                    if (type.includes("K")) {
                        window.electronAPI.printKOT(payload, item.name, `${item.size}mm`).then((resPrint) => {
                            if (!resPrint.success) {
                                toast.error('Print error.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    } else {
                        window.electronAPI.printBill(payload, item.name, `${item.size}mm`).then((resPrint) => {
                            if (!resPrint.success) {
                                toast.error('Print error.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                            }
                        });
                    }
                });
            }
        });
    }

    const selBill = (ord) => {
        setTerminal('update');
        setSelOrdIds([]);
        setSelOrd(ord._id);
        setOrdItems(ord.ordItems);
    }

    const updateOrder = () => {
        if (terminal === "update") {
            if (ordItems.length === 0) {
                toast.error('Please add an item.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                setSubmit(true);
                var body = {
                    ordid: getOID(),
                    oldid: selOrd,
                    items: ordItems,
                    total: getTotal(),
                    tax: getTax(),
                    time: moment().toISOString(),
                    isRoom: false,
                    tenentId: "66190e8338d3999c7e8cfbbe",
                    provider: "POS",
                    status: "open",
                }
                orderService.updateOrderWithRef(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        let payload = {
                            "name": company.tradNme,
                            "unit": table !== undefined ? table.title : getPType(),
                            "ordID": res.data.ordID,
                            "ordItems": res.data.ordItems,
                            "ordTime": res.data.ordTime,
                            "provider": res.data.provider
                        };
                        sendToPrint(payload, 'K');
                        toast.success("Order updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        setOrdItems([]);
                        setSelOrd('');
                        if (param.type === 'takeaway') {
                            setParam(prevParam => {
                                const tempParam = { ...prevParam, ordID: res.data.ordID };
                                loadData(tempParam);
                                return tempParam;
                            });
                        } else {
                            loadData();
                        }
                    } else {
                        toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            }
        }
    }

    const createBill = () => {
        let isBillAll = true;
        let TempList = [];
        let TempItemList = [];
        orders.map((ord) => {
            TempList.push(ord._id);
            if (ord.status !== 'billed') {
                isBillAll = false;
            }
            ord.ordItems.map((item) => {
                TempItemList.push(item);
            });
        });
        setSelOrdIds(TempList);
        setOrdItems(TempItemList);

        if (isBillAll) {
            let body = {
                invnRef: param.id,
                invnRT: param.index,
                status: "open",
            }
            invoiceService.getInvoiceWithRef(body, token).then((res) => {
                if (res.status) {
                    setInvoice(res.data);
                    setTerminal('settle');
                } else {
                    setTerminal('bill');
                }
            });
        } else {
            setTerminal('bill');
        }
    }

    const getinovID = () => {
        return "INV" + moment().format("MMDDYY");
    }

    const placeBill = () => {
        if (ordItems.length === 0) {
            toast.error('Please add an item.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        } else {
            if (terminal === 'bill') {
                setSubmit(true);
                let body = {
                    custId: selOrdIds,
                    invRef: param.id,
                    invRT: param.index,
                    invnNum: getinovID(),
                    items: ordItems,
                    total: getTotal(),
                    tax: getTax(),
                    status: "open",
                }
                invoiceService.setInvoicesWithRef(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        let payload = {
                            "name": company.tradNme,
                            "addr": company.Addr,
                            "phone": company.phone,
                            "gstn": company.gstn,
                            "invnNum": res.data.invnNum,
                            "items": res.data.items,
                            "total": res.data.total,
                            "tax": res.data.tax,
                            "createdAt": res.data.createdAt,
                            "provider": "POS"
                        };
                        sendToPrint(payload, 'B');
                        toast.success("New invoice added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        setOrdItems([]);
                        setSelOrdIds([]);
                        setTerminal("order");
                        loadData();
                    } else {
                        toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            } else {
                setSubmit(true);
                let body = {
                    invNum: invoice.invnNum,
                    invRef: invoice.invnRef,
                    invRT: invoice.invnRT,
                    pmode: pmode
                }
                invoiceService.settleInvoice(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        toast.success("Invoice has been settled!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        gotoBack();
                    } else {
                        toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            }
        }
    }

    const loadOrder = (isOrder) => {
        if (isOrder) {
            (async function () {
                let ordBody = {
                    'filter': {
                        'ordRef': param.id,
                        'ordRT': param.index,
                        'status': { "$in": ['open', 'running', 'billed'] }
                    }
                };
                var ordRes = await orderService.getAllOrders(ordBody, token);
                setOrders(ordRes.data);
            })();
        } else {
            (async function () {
                let ordBody = {
                    'filter': {
                        'ordRef': param.id,
                        'ordRT': param.index,
                        'ordID': param.ordID,
                        'status': { "$in": ['open', 'running', 'billed'] }
                    }
                };
                var ordRes = await orderService.getAllOrders(ordBody, token);
                setOrders(ordRes.data);
            })();
        }
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": 300,
                "searchQuery": query,
                "startDate": "",
                "endDate": ""
            };
            var res = await menuService.getAllMenu(body, token);
            if (res['status']) {
                setMenus(res.data.data);
                setMenusFilter(res.data.data);
                let tempCat = [];
                res.data.data.forEach(element => {
                    if (!tempCat.includes(element.category)) {
                        tempCat.push(element.category);
                    }
                });
                setCats(tempCat);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
            if (param.type === 'table') {
                let tblBody = { 'tid': param.id };
                var tblRes = await floorService.getFloorById(tblBody, token);
                setTerminal("order");
                setTable(tblRes.data.units[param.index]);
                loadOrder(true);
            } else if (param.type === 'takeaway') {
                setTerminal("order");
                loadOrder(false);
            }
        })();
    }

    const filterShort = (code) => {
        if (code === '-1') {
            setMenusFilter(menus);
        } else {
            const queryB = code.toLowerCase();
            let tempListB = menus.filter((item) => item.shortCode.toLowerCase().includes(queryB));
            setMenusFilter(tempListB);
        }
    };

    const addKeyItem = () => {
        if (terminal === "order" || terminal === "update") {
            let isItem = ordItems.findIndex((a) => a.shortCode.toLowerCase().includes(shortCode));
            let tempList = [...ordItems];
            if (isItem === -1) {
                let items = menus.filter((item) => item.shortCode.toLowerCase().includes(shortCode));
                if (items.length !== 0) {
                    let ordItem = {
                        id: items[0]._id,
                        name: items[0].name,
                        shortCode: shortCode,
                        category: items[0].category,
                        price: items[0].variant[0].price,
                        tax: items[0].variant[0].tax,
                        qty: 1,
                        status: 'none'
                    };
                    tempList.push(ordItem);
                }
            } else {
                tempList[isItem].qty = tempList[isItem].qty + 1;
            }
            setSelItemIndex(tempList.length - 1);
            setOrdItems(tempList);
        }
        setShortCode('-1');
        setMenusFilter(menus);
    }

    const updateQty = () => {
        if (terminal === "order") {
            if (quantity !== '0' || quantity !== '') {
                let isItem = selItemIndex;
                let tempList = [...ordItems];
                tempList[isItem].qty = parseInt(quantity);
                setOrdItems(tempList);
                setQuantity('0');
                setMode('order');
            }
        } else if (terminal === "update") {
            if (quantity !== '0' || quantity !== '') {
                let isItem = selItemIndex;
                let tempList = [...ordItems];
                tempList[isItem].qty = parseInt(quantity);
                setOrdItems(tempList);
                setQuantity('0');
                setMode('update');
            }
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        if (param.type === 'takeaway') {
            loadData();
        }
    }, [param]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.ctrlKey && event.key === 'o') {
                event.preventDefault();
                setMode((prevMode) => (prevMode === 'order' ? 'idle' : 'order'));
            } else if (event.key === 'Escape') {
                if (mode !== 'idle') {
                    setMode('idle');
                } else {
                    gotoBack();
                }
            } else if (mode === 'order' && event.key === 'Enter') {
                addKeyItem();
                setMode('qty');
            } else if (mode === 'qty' && event.key === 'Enter') {
                updateQty();
            } else if (mode === 'update' && event.key === 'Enter') {
                updateOrder();
                setSelItemIndex(-1);
                setMode('idle');
            } else if (mode === 'order') {
                if (/^[a-zA-Z0-9]$/.test(event.key)) {
                    let code = '';
                    if (shortCode === "-1") {
                        code = event.key;
                    } else {
                        code = shortCode + event.key;
                    }
                    setShortCode(code);
                    filterShort(code);
                } else if (event.key === 'Backspace') {
                    let code = '';
                    if (shortCode === "") {
                        code = "-1";
                    } else {
                        code = shortCode.slice(0, -1);
                    }
                    setShortCode(code);
                    filterShort(code);
                }
            } else if (mode === 'qty') {
                if (/^[0-9]$/.test(event.key)) {
                    let qty = '';
                    if (quantity === "0") {
                        qty = event.key;
                    } else {
                        qty = quantity + event.key;
                    }
                    setQuantity(qty);
                } else if (event.key === 'Backspace') {
                    let qty = '';
                    if (quantity === "") {
                        qty = "0";
                    } else {
                        qty = quantity.slice(0, -1);
                    }
                    setQuantity(qty);
                } else if (event.key === 'ArrowUp') {
                    let index = selItemIndex + 1;
                    if (index === ordItems.length) {
                        index = 0;
                    }
                    setSelItemIndex(index);
                } else if (event.key === 'ArrowDown') {
                    let index = selItemIndex - 1;
                    if (index === -1) {
                        index = ordItems.length - 1;
                    }
                    setSelItemIndex(index);
                }
            } else if (mode === 'edit') {
                if (event.key === 'ArrowUp') {
                    let index = selItemIndex + 1;
                    if (index === orders.length) {
                        index = 0;
                    }
                    setSelItemIndex(index);
                } else if (event.key === 'ArrowDown') {
                    let index = selItemIndex - 1;
                    if (index === -1) {
                        index = orders.length - 1;
                    }
                    setSelItemIndex(index);
                } else if (event.key === 'Enter') {
                    selBill(orders[selItemIndex]);
                    setSelItemIndex(0);
                    setMode('qty');
                }
            } else if (event.ctrlKey && event.key === 'k') {
                event.preventDefault();
                if (mode === "idle") {
                    placeOrder();
                }
            } else if (event.ctrlKey && event.key === 'b') {
                if (mode === "idle" && terminal === 'order') {
                    createBill();
                } else if (mode === "idle" && (terminal === 'bill' || terminal === 'settle')) {
                    placeBill();
                }
            } else if (event.ctrlKey && event.key === 'e') {
                event.preventDefault();
                if (mode === "idle") {
                    setSelItemIndex(0);
                    setMode('edit');
                } else if (mode === "edit") {
                    setSelItemIndex(-1);
                    setMode('idle');
                }
            } else if (event.ctrlKey && event.key === 'q') {
                if (mode === 'update') {
                    setMode('qty');
                }
            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, [mode, quantity, shortCode, quantity, terminal, orders, selItemIndex]);

    return (
        <div className='h-screen w-full'>
            <div className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-arrow-left cursor-pointer" onClick={() => { gotoBack(); }}></motion.i> POS Terminal
                            </h2>
                            <TopBar />
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <div className="mt-2">
                        <ScrollArea className="w-full h-[92vh] overflow-hidden">
                            <div className='w-full min-h-[92vh] bg-gray-100 rounded-md grid grid-cols-12 gap-2 p-2'>
                                <div className='col-span-2 h-full border-r p-2'>
                                    <ul>
                                        <li className='pb-2'>
                                            <p className='font-medium'>Mode: <span className='ml-2 bg-prime text-sm text-white py-[2px] px-4 rounded-full'>{mode.toUpperCase()}</span></p>
                                        </li>
                                        <li>
                                            <input type="text" id="itemSearch" ref={searchARef} onChange={() => { handleChangeForm('itemSearch') }} autoComplete="off" className="my-2 p-2 block w-full h-9 shadow-inner bg-white text-sm border rounded-md outline-none" placeholder="Search by name" />
                                        </li>
                                        <li>
                                            <input type="text" id="itemCode" ref={searchBRef} onChange={() => { handleChangeForm('itemCode') }} autoComplete="off" className="my-2 p-2 block w-full h-9 shadow-inner bg-white text-sm border rounded-md outline-none" placeholder="Search by code" />
                                        </li>
                                        <hr />
                                        <li className={`py-2 uppercase text-sm font-medium my-2 cursor-pointer rounded-md px-2 border border-dashed ${selCat === 'all' ? 'text-prime border-prime bg-red-100' : 'hover:bg-gray-200'}`} onClick={() => {
                                            sortTable('all');
                                        }}>
                                            All CATEGORY
                                        </li>
                                        {
                                            cats.length !== 0 && cats.map((cat, index) => {
                                                return <li className={`py-2 uppercase text-sm font-medium my-2 cursor-pointer rounded-md px-2 border border-dashed ${selCat === cat ? 'text-prime border-prime bg-red-100' : 'hover:bg-gray-200'}`} key={index}>
                                                    <div className='flex items-center justify-between'>
                                                        <span className='flex-grow' onClick={() => { sortTable(cat); }}>{cat}</span>
                                                    </div>
                                                </li>
                                            })
                                        }
                                    </ul>
                                </div>
                                <div className='col-span-5'>
                                    <ScrollArea className='w-full h-[90vh] bg-white rounded'>
                                        <div class="grid grid-cols-2 gap-4 p-4 overflow-y-auto">
                                            {
                                                menusFilter.length === 0 && <div className='col-span-3'>
                                                    <h1 className='text-lg text-center text-prime'>No data found.</h1>
                                                </div>
                                            }
                                            {
                                                menusFilter.length !== 0 && menusFilter.map((item, index) => {
                                                    return <div className={`px-3 py-3 flex flex-col border ${mode === 'order' && (item.shortCode.includes(shortCode)) ? "border-red-600" : "border-gray-200"} rounded-md h-32 justify-between cursor-pointer hover:shadow-md`} key={index} onClick={() => {
                                                        addItem(item);
                                                    }}>
                                                        <div>
                                                            <div className="font-bold text-gray-800 text-base">{item.name} (<span className='text-sm'>{item.type}</span>)</div>
                                                            <span className="font-light text-sm text-gray-400">{item.category}</span>
                                                        </div>
                                                        <div className="flex flex-row justify-between items-center">
                                                            <span className="self-end font-bold text-base text-prime">₹ {item.variant[0].price}</span>
                                                            <img src="https://placehold.co/200x200" className=" h-12 w-12 object-cover rounded-md" alt="" />
                                                        </div>
                                                    </div>
                                                })
                                            }
                                        </div>
                                    </ScrollArea>
                                </div>
                                <div className="col-span-3 px-4 bg-white rounded">
                                    <div className="flex flex-row items-center justify-between mt-4">
                                        <div className="font-bold text-xl">{table !== undefined ? table.title : getPType()}</div>
                                    </div>
                                    <div className="w-full bg-gray-100 rounded mt-4 min-h-[200px]">
                                        {
                                            ordItems.length === 0 && <div className='p-2 w-full h-[200px]'>
                                                <p className='text-sm text-prime text-center'>No items added.</p>
                                            </div>
                                        }
                                        {
                                            ordItems.length !== 0 && ordItems.map((item, index) => {
                                                return <div className={`p-2 ${mode === 'qty' && index === selItemIndex ? 'border border-red-600' : ''}`}>
                                                    <div className="flex flex-row justify-between items-center">
                                                        <div className="flex flex-row items-center w-2/5">
                                                            <img src="https://placehold.co/400x400" className="w-8 h-8 object-cover rounded-md" alt="" />
                                                            <span className="ml-1 font-semibold text-sm">{item.name}</span>
                                                        </div>
                                                        <div className="flex justify-between items-center">
                                                            {
                                                                item.qty > 1 && <div className="w-[24px] h-[24px] rounded-md bg-gray-300 flex items-center justify-center" onClick={() => { removeQty(item.id); }}>
                                                                    <p>-</p>
                                                                </div>
                                                            }
                                                            {
                                                                item.qty === 1 && <div className="w-[24px] h-[24px] rounded-md bg-red-300 flex items-center justify-center text-white" onClick={() => { removeItem(item.id); }}>
                                                                    <p>x</p>
                                                                </div>
                                                            }
                                                            <span className="font-semibold mx-2">{item.qty}</span>
                                                            <div className="w-[24px] h-[24px] rounded-md bg-gray-300 flex items-center justify-center" onClick={() => { addQty(item.id); }}>
                                                                <p>+</p>
                                                            </div>
                                                        </div>
                                                        <div class="font-semibold text-lg w-16 text-center">
                                                            ₹ {(item.price * item.qty)}
                                                        </div>
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div class="w-full mt-2">
                                        <div class="pt-4 rounded-md shadow-lg">
                                            <div class=" px-4 flex justify-between ">
                                                <span class="font-semibold text-sm">Subtotal</span>
                                                <span class="font-bold">₹ {getTotal()}</span>
                                            </div>
                                            <div class=" px-4 flex justify-between ">
                                                <span class="font-semibold text-sm">Sales Tax</span>
                                                <span class="font-bold">₹ {getTax()}</span>
                                            </div>
                                            <div class="border-t-2 mt-3 py-2 px-4 flex items-center justify-between">
                                                <span class="font-semibold text-xl">Total</span>
                                                <span class="font-bold text-xl">₹ {getTotal() + getTax()}</span>
                                            </div>
                                        </div>
                                    </div>
                                    {
                                        terminal === "order" && <div class="w-full mt-2">
                                            <div class="mt-4 flex">
                                                <div class="px-4 py-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer mx-2 flex-grow" onClick={() => { placeOrder(); }}>
                                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                    {!submit && "KOT"}
                                                </div>
                                                <div class="px-4 py-2 rounded-md shadow-lg text-center bg-ascent text-white font-semibold cursor-pointer mx-2 flex-grow" onClick={() => {
                                                    placeOrder();
                                                    //Todo: add option to print KOT
                                                }}>
                                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                    {!submit && "KOT & Print"}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        terminal === "update" && <div class="w-full mt-2">
                                            <div class="mt-4 flex">
                                                <div class="px-4 py-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer mx-2 flex-grow" onClick={() => { updateOrder(); }}>
                                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                    {!submit && "UPDATE"}
                                                </div>
                                                <div class="px-4 py-2 rounded-md shadow-lg text-center bg-ascent text-white font-semibold cursor-pointer mx-2 flex-grow" onClick={() => {
                                                    updateOrder();
                                                    //Todo: add option to print KOT
                                                }}>
                                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                    {!submit && "UPDATE & PRINT"}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        terminal === "bill" && <div class="w-full mt-2">
                                            <div class="mt-4 flex">
                                                <div class="px-4 py-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer mx-2 flex-grow" onClick={() => { placeBill(); }}>
                                                    {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                    {!submit && "GENRATE BILL"}
                                                </div>
                                            </div>
                                        </div>
                                    }
                                    {
                                        terminal === 'settle' && <>
                                            <div className='flex items-center justify-evenly my-6'>
                                                <div className='flex items-center'>
                                                    <input id="cash-radio" type="radio" checked={pmode === 'cash'} onChange={() => {
                                                        setPMode('cash');
                                                    }} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                    <label for="cash-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">CASH</label>
                                                </div>
                                                <div className='flex items-center'>
                                                    <input id="upi-radio" type="radio" checked={pmode === 'upi'} onChange={() => {
                                                        setPMode('upi');
                                                    }} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                    <label for="upi-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">UPI</label>
                                                </div>
                                                <div className='flex items-center'>
                                                    <input id="card-radio" type="radio" checked={pmode === 'card'} onChange={() => {
                                                        setPMode('card');
                                                    }} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                    <label for="card-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">CARD</label>
                                                </div>
                                                <div className='flex items-center'>
                                                    <input id="bank-radio" type="radio" checked={pmode === 'bank'} onChange={() => {
                                                        setPMode('bank');
                                                    }} className="w-4 h-4 text-blue-600 bg-gray-100 border-gray-300 focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600" />
                                                    <label for="bank-radio" class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300">BANK</label>
                                                </div>
                                            </div>
                                            <div class="w-full mt-2">
                                                <div class="mt-4">
                                                    <div class="px-4 py-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer" onClick={() => { placeBill(); }}>
                                                        {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                                        {!submit && "SETTLE BILL"}
                                                    </div>
                                                </div>
                                            </div>
                                        </>
                                    }
                                </div>
                                <div className='col-span-2'>
                                    <div className='flex items-center justify-between p-2'>
                                        <h2 class="text-xl font-medium font-serif truncate text-ascent uppercase">
                                            {tab}
                                        </h2>
                                        {
                                            tab === 'order' && <div className="font-semibold cursor-pointer" onClick={() => {
                                                createBill();
                                            }}>
                                                <span className="px-4 py-2 rounded-md bg-red-100 text-prime border border-prime text-sm">Make Bill</span>
                                            </div>
                                        }
                                    </div>
                                    <hr />
                                    {
                                        tab === "order" && <ScrollArea className="w-full h-[90vh] flex flex-col">
                                            {
                                                orders.length === 0 && <div className='w-full text-prime items-center justify-center mt-8'>
                                                    <p className='text-sm font-medium text-center'>No orders found.</p>
                                                </div>
                                            }
                                            {
                                                orders.map((ord, index) => {
                                                    return <div className={`w-full bg-white my-2 px-3 py-3 border rounded-md justify-between cursor-pointer hover:shadow-md ${mode === "edit" && index === selItemIndex ? 'border-prime' : 'border-gray-200'} ${(selOrd === ord._id || terminal === "bill") ? 'border-prime' : 'border-gray-200'}`} key={ord._id} onClick={() => {
                                                        selBill(ord);
                                                    }}>
                                                        <div className="font-bold text-gray-800"><b>{ord.ordID}</b></div>
                                                        <hr className='my-1' />
                                                        <div className='flex items-center'>
                                                            <p className='text-xs font-bold text-gray-950'>Item Name</p>
                                                            <div className='flex-grow'></div>
                                                            <p className='text-xs font-bold text-gray-950'>Item Unit</p>
                                                        </div>
                                                        <hr className='my-1' />
                                                        {
                                                            ord.ordItems.map((item) => {
                                                                return <div className='flex items-center'>
                                                                    <p className='text-xs  font-medium text-gray-950'>{item.name}</p>
                                                                    <div className='flex-grow'></div>
                                                                    <p className='text-xs font-medium'>{item.qty} x ₹ {item.price} </p>
                                                                </div>
                                                            })
                                                        }
                                                        <hr className='my-1' />
                                                        <div className="font-bold text-sm">Order Total : ₹ {ord.ordTotal.toFixed(2)}</div>
                                                        <hr className='my-1' />
                                                        <div className="text-sm">Status: <span className='text-xs font-bold text-prime'>{getOrderStatus(ord.status)}</span></div>
                                                    </div>
                                                })
                                            }
                                        </ScrollArea>
                                    }
                                </div>
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            </div>
        </div>
    )
}
