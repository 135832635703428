import { atom, DefaultValue } from 'recoil';

const localStorageEffect = key => ({ setSelf, onSet }) => {
    const savedValue = localStorage.getItem(key)
    if (savedValue != null) {
        setSelf(JSON.parse(savedValue));
    }

    onSet(newValue => {
        if (newValue instanceof DefaultValue) {
            localStorage.removeItem(key);
        } else {
            localStorage.setItem(key, JSON.stringify(newValue));
        }
    });
};

export const modeAtom = atom({
    key: "modeAtom",
    default: false,
    effects_UNSTABLE: [
        localStorageEffect('modeAtom'),
    ]
});

export const authAtom = atom({
    key: "authAtom",
    default: 'default',
    effects_UNSTABLE: [
        localStorageEffect('authAtom'),
    ]
});

export const userAtom = atom({
    key: "userAtom",
    default: {},
    effects_UNSTABLE: [
        localStorageEffect('userAtom'),
    ]
});

export const tokenAtom = atom({
    key: "tokenAtom",
    default: '',
    effects_UNSTABLE: [
        localStorageEffect('tokenAtom'),
    ]
});

export const userRoleAtom = atom({
    key: "userRoleAtom",
    default: "",
    effects_UNSTABLE: [
        localStorageEffect('userRoleAtom'),
    ]
});

export const companyAtom = atom({
    key: "companyAtom",
    default: {},
    effects_UNSTABLE: [
        localStorageEffect('companyAtom'),
    ]
});

export const subAtom = atom({
    key: "subAtom",
    default: {},
    effects_UNSTABLE: [
        localStorageEffect('subAtom'),
    ]
});

export const pathAtom = atom({
    key: "pathAtom",
    default: { main: 'home', sub: '', isToggle: false }
});


export const paramsAtom = atom({
    key: "paramsAtom",
    default: {}
});