import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { HandPlatter, Building, Trash2, CalendarClock, Eye, Timer } from "lucide-react";

import { useRecoilState } from 'recoil';
import { tokenAtom, paramsAtom } from '../../core/config/atoms';

import { toast } from 'react-toastify';

import TopBar from '../../core/widgets/ui/TopBar';
import ComponentTimer from 'core/widgets/util/ComponentTimer';
import DigShift from '../../core/widgets/ui/dailogs/DigShift';

import isRoom from '../../assets/image/isRoom.png';
import icTable from '../../assets/image/isTable.png';

import ServiceFloor from '../../services/serviceFloor';
import ServiceOrder from '../../services/serviceOrder';

export default function PanelPosMain() {

    const [token] = useRecoilState(tokenAtom);

    const [, setParam] = useRecoilState(paramsAtom);

    const [floors, setFloors] = useState([]);
    const [filterFloor, setFilterFloor] = useState([]);
    const [orders, setOrders] = useState([]);
    const [filterOrders, setFilterOrders] = useState([]);

    const [record, setRecord] = useState({});

    const [selFloor, setSelFloor] = useState('all');
    const [tab, setTab] = useState('all');

    const [tempURef, setTempURef] = useState('');
    const [tempURT, setTempURT] = useState(-1);

    const [loading, setLoading] = useState(true);
    const [selectionA, setSelectionA] = useState(false);
    const [selectionB, setSelectionB] = useState(false);
    const [selectionC, setSelectionC] = useState(false);

    const navigate = useNavigate();
    const floorService = new ServiceFloor();
    const orderService = new ServiceOrder();

    const sortTable = (filter) => {
        setSelFloor(filter);

        if (filter === 'all') {
            setFilterFloor(floors);
        } else {
            let tempList = floors.filter((item) => item.title === filter);
            setFilterFloor(tempList);
        }
    }

    const getColor = (sts) => {
        switch (sts) {
            case 'active':
                return 'bg-green-100';
            case 'overdeu':
                return 'bg-red-100';
            case 'inprog':
                return 'bg-yellow-100';
            case 'none':
            default:
                return 'bg-white';
        }
    }

    const gotoOrder = (tableId, tableIndex, type, ordID) => {
        setParam({ 'id': tableId, 'index': tableIndex, 'type': type, 'ordID': ordID });
        navigate(`/home/posOrder`);
    }

    const viewOrder = (tableId, tableIndex) => {
        (async function () {
            var body = {
                'ordRef': tableId,
                'ordRT': tableIndex,
            }
            var res = await orderService.getOrderByUnit(body, token);
            if (res.data.unit.status !== "none") {
                setRecord(res.data);
                setSelectionB(true);
            }
        })();
    }

    const siftUnit = (tableId, tableIndex) => {
        setTempURef(tableId);
        setTempURT(tableIndex);
        setSelectionC(true);
    }

    const gotoBook = (tableId, tableIndex) => {
        navigate(`/home/posBook`);
        //Todo: goto to booking option here.
    }

    const changeTab = (val) => {
        setTab(val);
        let tempList = [];
        switch (val) {
            case 'takeaway':
                filterOrders.forEach((ord) => {
                    if (ord.ordRef === 'takeaway') {
                        tempList.push(ord);
                    }
                });
                setOrders(tempList);
                break;
            case 'table':
                filterOrders.forEach((ord) => {
                    if (ord.ordRef !== 'takeaway') {
                        tempList.push(ord);
                    }
                });
                setOrders(tempList);
                break;
            case 'all':
            default:
                setOrders(filterOrders);
        }
    }

    const getOrderType = (ord) => {
        if (ord.ordRef === "takeaway") return "Take-Away";
        if (ord.ordRef === "delivery") return "Delivery";
        if (ord.isRoom) return "Room Service";
        if (!ord.isRoom) return "Table Service";
        return "NA"
    }

    const getChipColorA = (status) => {
        switch (status) {
            case 'open':
                return " bg-blue-100 text-blue-800";
            case 'running':
                return " bg-yellow-100 text-yellow-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getChipColorB = (status) => {
        switch (status) {
            case 'active':
                return 'bg-green-100 text-green-700 ';
            case 'overdeu':
                return 'bg-red-100 text-red-700';
            case 'inprog':
                return 'bg-yellow-100 text-yellow-700';
            case 'none':
            default:
                return 'bg-gray-100 text-gray-700';
        }
    }

    const getTotal = (ordItems) => {
        let total = 0;
        ordItems.forEach((a) => {
            total = total + (a.price * a.qty);
        });
        return total;
    }

    const getTax = (ordItems) => {
        let tax = 0;
        ordItems.forEach((a) => {
            tax = tax + ((a.price * a.qty) * (a.tax / 100));
        });
        return tax;
    }

    const cancelOrder = (id) => {
        let body = {
            'oid': id,
            "type": "cancel",
            'data': {
                'status': 'cancel'
            }
        };
        orderService.updateOrder(body, token).then((res) => {
            toast.success("Order has been updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            loadData();
        });
    }

    const onClose = () => {
        setTempURef('');
        setTempURT(-1);
        setSelectionB(false);
        setSelectionC(false);
        loadData();
    }

    const selectOrder = (ord) => {
        setRecord(ord);
        setSelectionA(true);
    }

    const loadOrder = () => {
        (async function () {
            let ordBody = {
                'filter': {
                    'status': { "$in": ['open', 'running'] }
                }
            };
            var ordRes = await orderService.getAllOrders(ordBody, token);
            setOrders(ordRes.data);
            setFilterOrders(ordRes.data);
        })();
    }

    const loadData = () => {
        setLoading(true);
        (async function () {
            let body = {};
            var doc = await floorService.getAllFloor(body, token);
            setFloors(doc.data);
            setFilterFloor(doc.data);
            setSelFloor('all');
            setLoading(false);
            loadOrder();
        })();
    }

    useEffect(() => {
        const handleKeyDown = (event) => {
            event.preventDefault();
            if (event.ctrlKey && event.key === 'd') {
                gotoOrder('', 0, 'takeaway');
            } if (event.ctrlKey && event.key === 'a') {

            }
        };

        window.addEventListener('keydown', handleKeyDown);

        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    useEffect(() => {
        loadData();
    }, []);

    return (
        <>
            {selectionC && <DigShift onClose={onClose} status={selectionC} ordRef={tempURef} ordRT={tempURT} />}
            {
                selectionA && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>KOT #{record.ordID}</h1>
                                    <span onClick={() => {
                                        setSelectionA(false);
                                        setRecord({});
                                        loadOrder();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <div className='flex mt-4'>
                                    <p className='font-sans font-medium'>Order Type:
                                        <span className={`ml-4 px-3 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-blue-100 text-blue-800`}>
                                            {getOrderType(record)}
                                        </span>
                                    </p>
                                    <p className='ml-8 font-sans font-medium'>Status:
                                        <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(record.status)}`}>
                                            {record.status}
                                        </span>
                                    </p>
                                </div>
                                <hr className='my-2' />
                                <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                    <span className='flex items-center'>
                                        <i className="las la-file-invoice mr-2 text-xl"></i>Items Summery
                                    </span>
                                </div>
                                <div className='mt-4 flex items-center'>
                                    <p className='text-sm font-bold text-gray-950'>Item Name</p>
                                    <div className='flex-grow'></div>
                                    <p className='text-sm font-bold text-gray-950'>Item Unit</p>
                                </div>
                                <hr className='my-2' />
                                {
                                    record.ordItems.map((item) => {
                                        return <div className='flex items-center'>
                                            <p className='text-sm  font-medium text-gray-950'>{item.name}</p>
                                            <div className='flex-grow'></div>
                                            <p className='text-sm font-medium'>{item.qty} x ₹ {item.price} </p>
                                        </div>
                                    })
                                }
                                <hr className='my-2' />
                                <div class="w-full mt-2">
                                    <div class="flex justify-between ">
                                        <span class="font-semibold text-base">Subtotal</span>
                                        <span class="font-bold">₹ {getTotal(record.ordItems)}</span>
                                    </div>
                                    <div class="flex justify-between ">
                                        <span class="font-semibold text-base">Sales Tax</span>
                                        <span class="font-bold">₹ {getTax(record.ordItems)}</span>
                                    </div>
                                    <div class="border-t-2 mt-3 py-2 flex items-center justify-between">
                                        <span class="font-semibold text-xl">Total</span>
                                        <span class="font-bold text-xl">₹ {getTotal(record.ordItems) + getTax(record.ordItems)}</span>
                                    </div>
                                </div>
                                <div className='flex mt-4'>
                                    <div class="py-2 mr-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer flex-grow" onClick={() => {
                                        setSelectionA(false);
                                        gotoOrder(record.ordRef, record.ordRT, record.ordRef === "takeaway" ? "takeaway" : "table", record.ordID);
                                    }}>
                                        Bill Order
                                    </div>
                                    <div class="py-2 ml-2 rounded-md shadow-lg text-center bg-ascent text-white font-semibold cursor-pointer flex-grow" onClick={() => {
                                        setSelectionA(false);
                                        cancelOrder(record._id);
                                    }}>
                                        CANCEL ORDER
                                    </div>
                                </div>
                            </div>
                        </ScrollArea >
                    </div >
                </div >
            }
            {
                selectionB && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                <div className='flex items-center justify-between text-2xl'>
                                    <h1 className='font-sans font-medium text-gray-900 uppercase'>UNIT : {record.unit.title}</h1>
                                    <span onClick={() => {
                                        setSelectionB(false);
                                        loadData();
                                    }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                </div>
                                <div className='flex mt-4'>
                                    <p className='font-sans font-medium'>Status:
                                        <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorB(record.unit.status)}`}>
                                            {record.unit.status}
                                        </span>
                                    </p>
                                </div>
                                <hr className='my-2' />
                                {
                                    record.orders.map((order) => {
                                        return <><div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-file-invoice mr-2 text-xl"></i>KOT #{order.ordID}
                                            </span>
                                        </div>
                                            <div className='mt-4 flex items-center'>
                                                <p className='text-sm font-bold text-gray-950'>Item Name</p>
                                                <div className='flex-grow'></div>
                                                <p className='text-sm font-bold text-gray-950'>Item Unit</p>
                                            </div>
                                            <hr className='my-2' />
                                            {
                                                order.ordItems.map((item) => {
                                                    return <div className='flex items-center'>
                                                        <p className='text-sm  font-medium text-gray-950'>{item.name}</p>
                                                        <div className='flex-grow'></div>
                                                        <p className='text-sm font-medium'>{item.qty} x ₹ {item.price} </p>
                                                    </div>
                                                })
                                            }
                                        </>
                                    })
                                }
                                <hr className='my-2' />
                                <div className='flex mt-4'>
                                    <div class="py-2 mr-2 rounded-md shadow-lg text-center bg-prime text-white font-semibold cursor-pointer flex-grow" onClick={() => {
                                        siftUnit(record.orders[0].ordRef, record.orders[0].ordRT);
                                    }}>
                                        Shift Unit
                                    </div>
                                </div>
                            </div>
                        </ScrollArea >
                    </div >
                </div >
            }
            <div className='h-screen w-full'>
                <div className="w-full h-full py-3 px-3">
                    <div className="grid grid-cols-12 grid-gap-2">
                        <div className="col-span-12 mt-2">
                            <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                                <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                    POS Terminal
                                </h2>
                                <TopBar />
                            </div>
                        </div>
                    </div>
                    <div className="col-span-12 mt-4">
                        <div className="mt-2">
                            <ScrollArea className="w-full h-[100vh] overflow-hidden">
                                <div className='w-full min-h-[100vh] bg-gray-100 rounded-md grid grid-cols-12 gap-2 p-2'>
                                    <div className='col-span-2 h-full border-r p-2'>
                                        <button className="w-full h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white text-sm" onClick={() => {
                                            gotoOrder('', 0, 'takeaway')
                                        }}><i class="las la-plus mr-1"></i> ADD TAKE AWAY</button>
                                        <ul>
                                            <li className={`py-2 uppercase text-sm font-medium my-2 cursor-pointer rounded-md px-2 border border-dashed ${selFloor === 'all' ? 'text-prime border-prime bg-red-100' : 'hover:bg-gray-200'}`} onClick={() => {
                                                sortTable('all');
                                            }}>
                                                All Floors
                                            </li>
                                            {
                                                floors.length !== 0 && floors.map((cunit) => {
                                                    return <li className={`py-2 uppercase text-sm font-medium my-2 cursor-pointer rounded-md px-2 border border-dashed ${selFloor === cunit.title ? 'text-prime border-prime bg-red-100' : 'hover:bg-gray-200'}`} key={cunit._id}>
                                                        <div className='flex items-center justify-between'>
                                                            <span className='flex-grow' onClick={() => { sortTable(cunit.title); }}>{cunit.title}</span>
                                                        </div>
                                                    </li>
                                                })
                                            }
                                        </ul>
                                    </div>
                                    <div className='col-span-8'>
                                        {
                                            filterFloor.length !== 0 && filterFloor.map((cuint) => {
                                                return <div className='w-full my-4 border border-gray-200 border-dashed p-1 rounded'>
                                                    <div className='w-full'>
                                                        <div className='flex items-center justify-between'>
                                                            <div className='flex items-center'>
                                                                <Building size={22} />
                                                                <h1 className='text-xl font-medium uppercase ml-1'>{cuint.title}</h1>
                                                            </div>
                                                        </div>
                                                        <div className='my-2 h-[1px] bg-gray-300'></div>
                                                    </div>
                                                    <div className='w-full grid grid-cols-5 gap-2'>
                                                        {
                                                            cuint.units.length === 0 && < div className='h-[114px] p-2 rounded shadow col-span-6 border border-dashed border-prime cursor-pointer hover:shadow-md flex items-center justify-center'>
                                                                <h1 className='text-sm font-medium text-prime'>No data found.</h1>
                                                            </div>
                                                        }
                                                        {
                                                            cuint.units.length !== 0 && cuint.units.map((unit, index) => {
                                                                if (unit.type === 'Room') {
                                                                    return < div className={`h-[114px] p-2 rounded shadow col-span-1 border border-dashed border-gray-400 cursor-pointer hover:shadow-md ${getColor(unit.status)}`} key={index} onClick={() => {
                                                                        // gotoBook(cuint._id, index);
                                                                        //Todo: add code here.
                                                                    }}>
                                                                        <div className='flex justify-between items-center px-1'>
                                                                            <div>
                                                                                <p className='text-xl font-medium font-serif'>{unit.title}</p>
                                                                                <div className='flex items-center'>
                                                                                    <CalendarClock size={18} />
                                                                                    <ComponentTimer startTime={unit.endTime} isOn={unit.status !== 'none'} isElapsed={true} />
                                                                                </div>
                                                                            </div>
                                                                            <img src={isRoom} alt='Table icon' className='w-[54px] h-[54px]' />
                                                                        </div>
                                                                        <div className='bg-gray-200 h-[1px] my-2'></div>
                                                                        <div className='flex items-center justify-between'>
                                                                            <div className='text-gray-400 flex items-center'>
                                                                                <HandPlatter size={20} />
                                                                                <p className='text-base font-medium font-serif ml-1'>₹ {unit.pending.toFixed(2)}</p>
                                                                            </div>
                                                                            <Eye size={18} className='cursor-pointer' onClick={() => {
                                                                                // deleteTable(cuint, index);
                                                                            }} />
                                                                        </div>
                                                                    </div>
                                                                } else {
                                                                    return < div className={`h-[114px] p-2 rounded shadow col-span-1 border border-dashed border-gray-400 cursor-pointer hover:shadow-md ${getColor(unit.status)}`} key={index}>
                                                                        <div className='flex justify-between items-center px-1' onClick={() => { gotoOrder(cuint._id, index, 'table'); }}>
                                                                            <div>
                                                                                <p className='text-xl font-medium font-serif'>{unit.title}</p>
                                                                                <p className='text-base font-medium font-serif'>₹ {unit.pending.toFixed(2)}</p>
                                                                            </div>
                                                                            <img src={icTable} alt='Table icon' className='w-[54px] h-[54px]' />
                                                                        </div>
                                                                        <div className='bg-gray-200 h-[1px] my-2'></div>
                                                                        <div className='flex items-center justify-between'>
                                                                            <div className='text-gray-400 flex items-center'>
                                                                                <Timer size={20} />
                                                                                <ComponentTimer startTime={unit.startTime} isOn={unit.status !== 'none'} isElapsed={false} />
                                                                            </div>
                                                                            <Eye size={18} className='cursor-pointer' onClick={() => { viewOrder(cuint._id, index); }} />
                                                                        </div>
                                                                    </div>
                                                                }
                                                            })
                                                        }
                                                    </div>
                                                </div>
                                            })
                                        }
                                    </div>
                                    <div className='col-span-2'>
                                        <div className='flex items-center justify-between p-2'>
                                            <h2 class="text-xl font-medium font-serif truncate text-ascent">
                                                Running Orders
                                            </h2>
                                        </div>
                                        <div class="w-full flex justify-between items-center">
                                            <div className='h-12 bg-gray-100 rounded'>
                                                <ul className="intro-y flex text-sm font-sans">
                                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'all' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('all') }}>All</button></li>
                                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'takeaway' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('takeaway') }}>Take-Away</button></li>
                                                    <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'table' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('table') }}>Table</button></li>
                                                </ul>
                                            </div>
                                        </div>
                                        <hr />
                                        <ScrollArea className="w-full h-[90vh] flex flex-col">
                                            {
                                                orders.length === 0 && <div className='w-full text-prime items-center justify-center mt-8'>
                                                    <p className='text-sm font-medium text-center'>No orders found.</p>
                                                </div>
                                            }
                                            {
                                                orders.map((ord) => {
                                                    return <div className={`w-full h-[120px] bg-white my-2 px-3 py-3 border rounded-md justify-between cursor-pointer hover:shadow-md border-gray-200`} key={ord._id} onClick={() => {
                                                        selectOrder(ord);
                                                    }}>
                                                        <div className="font-bold text-gray-800 text-base"><b>{ord.ordID}</b></div>
                                                        <div className="font-light text-sm text-gray-400">{ord.ordItems.length} x Items</div>
                                                        <div className="font-bold mt-2">Order Total : ₹ {ord.ordTotal.toFixed(2)}</div>
                                                        <hr />
                                                        <div className="font-bold text-red-600 text-xs my-1">{getOrderType(ord)}</div>
                                                    </div>
                                                })
                                            }
                                        </ScrollArea>
                                    </div>
                                </div>
                            </ScrollArea>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
