import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceBOM {
    lookupService = new APIServiceLookUp();

    async setBOM(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}bom/setBOM`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllBOM(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}bom/getAllBOM`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateBOM(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}bom/updateBOM`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delBOM(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}bom/delBOM`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}