import React from 'react';
import { useParams } from 'react-router-dom';

import PanelStaffList from '../panels/staff/PanelStaffList';
import PanelStaffAdd from '../panels/staff/PanelStaffAdd';
import PanelStaffEdit from '../panels/staff/PanelStaffEdit';

import PanelDev from '../panels/dev/PanelDev';

export default function LayoutStaff() {

    const { page } = useParams();

    return (
        <div className='h-screen w-full'>
            {
                (() => {
                    switch (page) {
                        case 'staff':
                            return (<PanelStaffList />);
                        case 'staffAdd':
                            return (<PanelStaffAdd />);
                        case 'staffEdit':
                            return (<PanelStaffEdit />);
                        default:
                            return (<PanelDev />);
                    }
                })()
            }
        </div>
    )
}
