import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../config/atoms';

import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

import ServiceFloor from 'services/serviceFloor';
import ServiceOrder from 'services/serviceOrder';

export default function DigShift(props) {

    const [token] = useRecoilState(tokenAtom);

    const [units, setUnits] = useState([]);

    const [submit, setSubmit] = useState(false);

    const floorService = new ServiceFloor();
    const orderService = new ServiceOrder();

    const formVSchema = Yup.object().shape({
        unit: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            'unit': ''
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            var vals = values.unit.split(':');
            var body = {
                'oRef': props.ordRef,
                'oRT': props.ordRT,
                'nRef': vals[0],
                'nRT': vals[1],
            };
            orderService.shiftUnit(body, token).then((d) => {
                resetForm();
                setSubmit(false);
                if (d.status) {
                    toast.success("Table / Room Shifted.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                } else {
                    toast.error("Table / Room Shift error.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
                props.onClose();
            });
        }
    });

    const loadData = () => {
        (async function () {
            let body = {};
            var docs = await floorService.getAllFloor(body, token);
            let tempUnit = [];
            docs.data.map((item) => {
                item.units.map((unit, index) => {
                    if (unit.status === "none") {
                        tempUnit.push({ 'id': item._id, index: index, title: unit.title, value: `${item._id}:${index}` });
                    }
                });
            });
            setUnits(tempUnit);
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Dialog open={props.status} onClose={() => { props.onClose(); }} className='overflow-y-auto overflow-x-hidden z-[100] w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                <div className='flex items-center justify-between'>
                    <h3 className="text-xl font-semibold text-gray-900">
                        Shift Unit &nbsp;
                    </h3>
                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                        props.onClose();
                    }}>
                        <i className="las la-times text-sm text-black"></i>
                    </span>
                </div>
                <hr className='mt-2' />
                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Select Unit<sup className="text-red-600">*</sup></label>
                        <select type="text" name="unit" id="unit" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Table 101 / Room 101" onChange={handleChange} value={values.unit}>
                            <option value="">-- Select Unit --</option>
                            {
                                units.map((unit) => {
                                    return <option value={unit.value} selected={unit.value == values.unit}>{unit.title}</option>
                                })
                            }
                        </select>
                        {(errors.unit && touched.unit) && <p className='text-xs text-red-400 mt-1'>{errors.unit}</p>}
                    </div>
                    <div className='flex items-end'>
                        <button type="submit" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "SHIFT"}
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}
