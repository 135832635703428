import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceSubsciption {

    lookupService = new APIServiceLookUp();

    async getAllSubscription(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}subscription/getAllSubscription`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}