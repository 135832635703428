import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceInvoice {
    lookupService = new APIServiceLookUp();

    async setInvoicesWithRef(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}invoice/setInvoicesWithRef`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getInvoiceWithRef(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}invoice/getInvoiceWithRef`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async settleInvoice(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}invoice/settleInvoice`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllInvoicesT(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}invoice/getAllInvoicesT`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}