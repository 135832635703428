import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Calendar as CalendarIcon } from "lucide-react";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';
import Lottie from 'lottie-react';

import TopBar from '../../core/widgets/ui/TopBar';

import animPage from '../../assets/anim/anim-pageload.json';

import ServiceInventory from '../../services/serviceInventory';

export default function PanelInventoryList(props) {

    const [token] = useRecoilState(tokenAtom);

    const [records, setRecords] = useState([]);

    const [filter, setFilter] = useState({});
    const [record, setRecord] = useState({});

    const [date, setDate] = useState({
        from: Moment().startOf('month').toDate(),
        to: Moment().toDate(),
    });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);
    const [selection, setSelection] = useState(false);
    const [isProcessing, setIsProcessing] = useState(true);

    const navigate = useNavigate();
    const inventoryService = new ServiceInventory();

    const addAdjustment = () => {
        props.onClose();
        navigate(`/home/inventoryAdd`);
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        records.forEach((item) => {
            items.push({
                id: item.billId,
                type: item.billType,
                note: item.billNote,
                extraNote: item.extraNote,
                noi: item.items.length,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "Inventory");
        XLSX.writeFile(workbook, "Inventory Records.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['id,type,billNote,extraNote,length,status,createdAt'];
        let usersCsv = records.reduce((acc, rec) => {
            const { billId, billType, billNote, extraNote, items, status, createdAt } = rec
            acc.push([billId, billType, billNote, extraNote, items.length, status, createdAt].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'Inventory Records.csv',
            fileType: 'text/csv',
        });
    }

    const getBillType = (type) => {
        switch (type) {
            case "Opening":
                return "Opening Stock";
            case "Adjustment":
                return "Stock Adjustment";
            case "Purchase":
                return "Purchase Stock";
            case "Transfer":
                return "Transfer Stock";
            case "Received":
                return "Stock Received";
            case "Bom":
                return "Stock Consumed";
            default:
                return "NA";
        }
    }

    const onDateChange = (event) => {
        setIsDate(true);
        setDate(event);
    }

    const getChipColorA = (status) => {
        switch (status) {
            case 'active':
                return " bg-green-100 text-green-800";
            default:
                return " bg-gray-100 text-gray-800";
        }
    }

    const getStockVal = (item, type) => {
        switch (type) {
            case "Adjustment":
            case "Opening":
                return item.values;
            case "Bom":
            case "Transfer":
                return item.oldVal - parseFloat(item.values);
            case "Purchase":
            case "Received":
                return item.oldVal + parseFloat(item.values);
            default:
                return "0";
        }
    }

    const getStockUpdate = (item, type) => {
        switch (type) {
            case "Adjustment":
            case "Opening":
                if (parseFloat(item.values) >= parseFloat(item.oldVal)) {
                    return "+" + (parseFloat(item.values) - parseFloat(item.oldVal));
                } else {
                    return "-" + (parseFloat(item.oldVal) - parseFloat(item.values));
                }
            case "Bom":
            case "Transfer":
                return "-" + item.values;
            case "Purchase":
            case "Received":
                return "+" + item.values;
            default:
                return "0";
        }
    }

    const getStockColUpdate = (item, type) => {
        switch (type) {
            case "Opening":
            case "Adjustment":
            case "Bom":
                if (parseFloat(item.values) >= parseFloat(item.oldVal)) {
                    return "text-green-600"
                } else {
                    return "text-red-600"
                }
            case "Transfer":
                return "text-red-600";
            case "Purchase":
            case "Received":
                return "text-green-600"
            default:
                return "text-gray-600";
        }
    }

    const setSelRecord = (record) => {
        setSelection(true);
        setRecord(record);
    }

    const pageChange = (pageValue) => {
        (async function () {
            let body = {
                "filter": filter,
                "page": pageValue,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await inventoryService.getAllInventory(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setRecords(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": filter,
                "page": 1,
                "size": parseInt(pageSize),
                "searchQuery": query,
                "startDate": isDate ? date.from.toISOString() : "",
                "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            };
            var res = await inventoryService.getAllInventory(body, token);
            if (res['status']) {
                setPageCurrent(res.data.currentPage);
                setPageTotal(res.data.totalPage);
                setRecords(res.data.data);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, date, filter]);

    return (
        <>
            {
                selection && <div className='overflow-y-auto overflow-x-hidden z-50 w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
                    <div className='bg-white w-[600px] min-h-screen absolute right-0 p-[20px]'>
                        <ScrollArea>
                            <div className='px-4'>
                                {
                                    isProcessing && <>
                                        <div className='flex items-center justify-between text-2xl'>
                                            <h1 className='font-sans font-medium text-gray-900 uppercase'>Record #{record.billId}</h1>
                                            <span onClick={() => {
                                                setSelection(false);
                                                setRecord({});
                                                loadData();
                                            }}><i className="las la-times text-xl text-gray-700 cursor-pointer"></i></span>
                                        </div>
                                        <div className='flex mt-4'>
                                            <p className='font-sans font-medium'>Note Type:
                                                <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase bg-blue-100 text-blue-800`}>
                                                    {getBillType(record.billType)}
                                                </span>
                                            </p>
                                            <p className='ml-8 font-sans font-medium'>Status:
                                                <span className={`ml-4 px-4 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getChipColorA(record.status)}`}>
                                                    {record.status}
                                                </span>
                                            </p>
                                        </div>
                                        <hr className='my-2' />
                                        <p className='text-sm font-medium text-gray-950'>Record Note: </p>
                                        <p className='text-xs text-gray-600 mt-2'>{record.billNote}</p>
                                        <div className='p-2 bg-gray-200 w-full text-sm font-medium rounded mt-4 flex justify-between items-center'>
                                            <span className='flex items-center'>
                                                <i className="las la-file-invoice mr-2 text-xl"></i>Items Summery
                                            </span>
                                        </div>
                                        <div className='mt-4 flex items-center'>
                                            <p className='text-sm font-bold text-gray-950'>Item Name</p>
                                            <div className='flex-grow'></div>
                                            <p className='text-sm font-bold text-gray-950'>Stock Update</p>
                                        </div>
                                        <hr className='my-2' />
                                        {
                                            record.items.map((item) => {
                                                return <div className='flex items-center'>
                                                    <p className='text-sm  font-medium text-gray-950'>{item.name}</p>
                                                    <div className='flex-grow'></div>
                                                    <p className='text-sm font-medium'>{getStockVal(item, record.billType)} {item.unit}</p>
                                                    <p className={`text-sm px-2 py-1 rounded-full font-bold ${getStockColUpdate(item, record.billType)}`}>
                                                        ({getStockUpdate(item, record.billType)})
                                                    </p>
                                                </div>
                                            })
                                        }
                                    </>
                                }
                                {
                                    !isProcessing && <>
                                        <div className='flex items-center justify-center w-full h-full'>
                                            <Lottie animationData={animPage} className="w-40 h-40" loop={true} />
                                        </div>
                                    </>
                                }
                            </div>
                        </ScrollArea>
                    </div>
                </div>
            }
            <ScrollArea className="w-full h-full py-3 px-3">
                <div className="grid grid-cols-12 grid-gap-2">
                    <div className="col-span-12 mt-2">
                        <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                            <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                                <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle() }}></motion.i> Inventory Addjustment
                            </h2>
                            <TopBar />
                        </div>
                        <div class="w-full ml-2 mt-4 flex justify-between items-center">
                            <button className="h-10 py-1 px-3 rounded-md bg-prime hover:bg-primeLight text-white mr-4 text-sm" onClick={() => { addAdjustment(); }}><i class="las la-plus mr-1"></i> ADD ADDJUSTMENT</button>
                        </div>
                    </div>
                </div>
                <div className="col-span-12 mt-4 px-2">
                    <div className="w-full shadow-md rounded-md border">
                        <table className="min-w-full divide-y divide-gray-200 px-4">
                            <thead className="bg-gray-50">
                                <tr className=''>
                                    <th scope="col" className="border-b border-gray-200" colSpan={6}>
                                        <div className='w-full grid grid-cols-12 gap-2'>
                                            <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                                <div className='col-span-3 flex'>
                                                    <Popover>
                                                        <PopoverTrigger asChild>
                                                            <Button id="date" variant={"outline"}
                                                                className={cn(
                                                                    "w-[300px] justify-start text-left font-normal",
                                                                    !date && "text-muted-foreground"
                                                                )}
                                                            >
                                                                <CalendarIcon className="mr-2 h-4 w-4" />
                                                                {date?.from ? (
                                                                    date.to ? (
                                                                        <>
                                                                            {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                            {Moment(date.to).format('MMM DD, yyyy')}
                                                                        </>
                                                                    ) : (
                                                                        Moment(date.from).format('MMM DD, yyyy')
                                                                    )
                                                                ) : (
                                                                    <span>Pick a date</span>
                                                                )}
                                                            </Button>
                                                        </PopoverTrigger>
                                                        <PopoverContent className="w-auto p-0" align="start">
                                                            <Calendar
                                                                initialFocus
                                                                mode="range"
                                                                defaultMonth={date?.from}
                                                                selected={date}
                                                                onSelect={onDateChange}
                                                                numberOfMonths={2}
                                                            />
                                                        </PopoverContent>
                                                    </Popover>
                                                </div>
                                                <div className='w-full flex col-span-9 items-center'>
                                                    <div className='w-[80px]'>
                                                        <p>Search :</p>
                                                    </div>
                                                    <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by ID." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                                </div>
                                            </form>
                                            <div className='col-span-1 flex items-center'>
                                                <DropdownMenu>
                                                    <DropdownMenuTrigger>
                                                        <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                    </DropdownMenuTrigger>
                                                    <DropdownMenuContent>
                                                        <div
                                                            className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                            <div>
                                                                <ul className='flex flex-col items-start p-2'>
                                                                    <DropdownMenuItem>
                                                                        <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                            exportToExcel();
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                    <DropdownMenuItem>
                                                                        <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                            exportToCsv();
                                                                        }}>
                                                                            <div className='flex items-center text-base'>
                                                                                <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                            </div>
                                                                        </li>
                                                                    </DropdownMenuItem>
                                                                </ul>
                                                            </div>
                                                        </div>
                                                    </DropdownMenuContent>
                                                </DropdownMenu>
                                            </div>
                                        </div>
                                    </th>
                                </tr>
                                <tr>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        ID
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Bill Type
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Bill Note
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        ITEMS
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Status
                                    </th>
                                    <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                        Created At
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="bg-white divide-y divide-gray-200">
                                {
                                    records.length !== 0 && records.map((record) => {
                                        return <tr className="hover:bg-gray-100" key={record._id}>
                                            <td className="px-6 py-4 whitespace-nowrap cursor-pointer" onClick={() => {
                                                setSelRecord(record);
                                            }}>
                                                <div className="text-sm font-bold text-gray-900">
                                                    <div className='flex items-center text-prime'>
                                                        {record.billId}
                                                    </div>
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900 uppercase">
                                                    {getBillType(record.billType)}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    {record.billNote}
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-gray-900">
                                                    Items Changed ({record.items.length})
                                                </div>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${record.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                                    {record.status.toUpperCase()}
                                                </span>
                                            </td>
                                            <td className="px-6 py-4 whitespace-nowrap">
                                                <div className="text-sm font-bold text-blue-800">
                                                    {Moment(record.createdAt).format("DD MMM, yyyy hh:mm:ss")}
                                                </div>
                                            </td>
                                        </tr>
                                    })
                                }
                                {
                                    records.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                        <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                            <div className="text-sm text-red-600 text-center">
                                                No data found.
                                            </div>
                                        </td>
                                    </tr>
                                }
                            </tbody>
                        </table>
                        <div className='flex py-2 px-4 items-center bg-blue-50'>
                            <p className='text-sm font-serif'>Show rows per page</p>
                            <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                                <option value={10}>10</option>
                                <option value={20}>20</option>
                                <option value={40}>40</option>
                                <option value={50}>50</option>
                            </select>
                            <div className='flex-grow'></div>
                            <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                            <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                            <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        </div>
                    </div>
                </div>
            </ScrollArea>
        </>
    )
}
