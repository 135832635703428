import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom, paramsAtom } from '../../core/config/atoms';

import { ScrollArea } from "@/components/ui/scroll-area";

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import { motion } from "framer-motion";

import TopBar from '../../core/widgets/ui/TopBar';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

import ServiceRawwItem from '../../services/serviceRawwItem';

export default function PanelRawwAdd(props) {

    const [token] = useRecoilState(tokenAtom);
    const [userData] = useRecoilState(userAtom);
    const [param, setParam] = useRecoilState(paramsAtom);

    const [editId, setEditId] = useState('');

    const [submit, setSubmit] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const navigate = useNavigate();
    const rawwItemService = new ServiceRawwItem();

    const unitTypes = [
        { value: 'Pics', label: 'Pics' },
        { value: 'Box', label: 'Box' },
        { value: 'Pack', label: 'Pack' },
        { value: 'Sachet', label: 'Sachet' },
        { value: 'Kilogram', label: 'Kilogram' },
        { value: 'Gram', label: 'Gram' },
        { value: 'Milligram', label: 'Milligram' },
        { value: 'Kilolitre', label: 'Kilolitre' },
        { value: 'Litre', label: 'Litre' },
        { value: 'Milliliter', label: 'Milliliter' },
    ];

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        cat: Yup.string().required('This information is required'),
        unit: Yup.string().required('This information is required'),
        mini: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            name: '',
            cat: '',
            unit: '',
            mini: 0,
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (isEdit) {
                let body = {
                    "uid": editId,
                    "data": {
                        "itemName": values.name,
                        "itemCategory": values.cat,
                        "itemUnit": values.unit,
                        "itemMini": values.mini,
                    }
                }
                rawwItemService.updateRawwItem(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        toast.success("Raww Item Updated!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        goBack();
                    } else {
                        toast.error('Server error please try again..!!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            } else {
                let body = {
                    "id": generateID(),
                    "name": values.name,
                    "cat": values.cat,
                    "unit": values.unit,
                    "uid": userData._id,
                    'mini': values.mini,
                }
                rawwItemService.setRawwItem(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        toast.success("New raww item added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        goBack();
                    } else {
                        toast.error('Server error please try again..!!', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            }
        }
    });

    const generateID = () => {
        const timestamp = Date.now().toString();
        const randomNum = Math.floor(Math.random() * 10000).toString();
        const uniqueID = timestamp.slice(-6) + randomNum;
        return "I" + uniqueID.slice(0, 10);
    };

    const goBack = () => {
        props.onOpen();
        setParam({});
        navigate(`/home/raww`);
    }

    const loadData = () => {
        if (Object.keys(param).length !== 0) {
            (async function () {
                let body = {
                    "iid": param.id
                };
                var res = await rawwItemService.getRawwItemById(body, token);
                if (res['status']) {
                    setIsEdit(true);
                    setEditId(param.id);
                    setParam({});
                    let tempVal = {
                        name: res.data.itemName,
                        cat: res.data.itemCategory,
                        unit: res.data.itemUnit
                    }
                    setValues({ ...values, ...tempVal });
                } else {
                    toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                }
            })();
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                        <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Add Raww Material
                        </h2>
                        <TopBar />
                    </div>
                </div>
            </div>
            <div className="w-full h-full p-2 flex flex-col overflow-auto">
                <div className="p-3">
                    <form onSubmit={handleSubmit}>
                        <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                            <i className="las la-long-arrow-alt-left text-xl"></i>
                            <h4 className="text-xs text-black font-sans font-medium ml-2">Back to inventory</h4>
                        </div>
                        <div className="intro-y mt-4">
                            <h3 className="text-2xl font-sans font-bold text-gray-800">Add Raww Material</h3>
                            <p className="mt-1 text-sm text-gray-600 mb-6">
                                Please enter corret details and information related to raww material.
                            </p>
                            <hr />
                        </div>
                        <div className="mt-4">
                            <div className="grid grid-cols-12 gap-6">
                                <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                    <div className="shadow">
                                        <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                            <div className="grid grid-cols-6 gap-6">
                                                <div className="col-span-6">
                                                    <h3 className="text-lg font-medium leading-6 text-gray-900">Raww Item Information</h3>
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Item Name<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Item Name" value={values.name} />
                                                    {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Item Category<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="cat" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="Item Category" value={values.cat} />
                                                    {(errors.cat && touched.cat) && <p className='text-xs text-red-400 mt-1'>{errors.cat}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Item Unit<sup className="text-red-600">*</sup></label>
                                                    <select id="unit" className="appearance-none mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" onChange={handleChange} value={values.unit}>
                                                        <option value="" disabled="" selected="selected">Select</option>
                                                        {
                                                            unitTypes.length !== 0 && unitTypes.map((item) => {
                                                                return <option value={item.value} selected={values.uType == item.value}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {(errors.unit && touched.unit) && <p className='text-xs text-red-400 mt-1'>{errors.unit}</p>}
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Item Minimum Stock<sup className="text-red-600">*</sup></label>
                                                    <input type="number" id="mini" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 bg-white text-sm border-gray-300 border rounded outline-1 outline-blue-200" placeholder="i.e. 10" value={values.mini} />
                                                    {(errors.mini && touched.mini) && <p className='text-xs text-red-400 mt-1'>{errors.mini}</p>}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                            <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                                {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                                {!submit && (isEdit ? "UPDATE ITEM" : "ADD ITEM")}
                            </button>
                        </div>
                    </form >
                </div >
            </div>
        </ScrollArea>
    )
}
