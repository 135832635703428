import React, { useState, useEffect } from 'react';

import { Check } from "lucide-react";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import { toast } from 'react-toastify';
import moment from 'moment';

import TopBar from '../../core/widgets/ui/TopBar';

import ServiceSubscription from '../../services/serviceSubscription';

export default function PanelBilling(props) {

    const [token] = useRecoilState(tokenAtom);

    const [Subscription, setSubscription] = useState({});

    const subscriptionService = new ServiceSubscription();

    const gotoContact = () => {
        //Todo: Add goto contact listing here.
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": 10,
                "searchQuery": "",
                "startDate": "",
                "endDate": ""
            };
            var res = await subscriptionService.getAllSubscription(body, token);
            if (res['status']) {
                setSubscription(res.data.data[0]);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10">
                        <h2 class="text-2xl font-medium truncate ml-2 text-ascent">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Billing Profile
                        </h2>
                        <TopBar />
                    </div>
                    <hr className='mt-2' />
                    <div className='w-full mt-4 ml-4'>
                        <h1 className='text-2xl font-medium'>BookTable Subscriptions Plan</h1>
                        <p className='text-sm'>To start billing, please choose one of our plans</p>
                    </div>
                </div>
                <div className="col-span-12 mt-4">
                    <div className='border rounded-sm mx-4 h-12 flex items-center justify-between p-2'>
                        <span className='text-base font-medium text-prime'>Your Current Subscription Plan</span>
                        <span className='text-sm text-prime'>Free Demo Plan ending at {moment(Subscription.endDate ?? Date.now().toString()).format('DD, MMM yyyy')}</span>
                    </div>
                </div>
                <div className="col-span-12 mt-6 mx-4">
                    <div className='w-full grid grid-cols-3 gap-4 '>
                        <div className='col-span-1 border border-gray-200 rounded-md p-4 flex flex-col'>
                            <h2 className='text-lg font-medium text-prime'>Demo Plan</h2>
                            <h3 className='text-5xl font-medium mt-[60px]'>₹ 0</h3>
                            <hr className='mt-2' />
                            <ul className='mt-2'>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> Free Trail of All The Services</li>
                            </ul>
                            <div className='flex-grow'></div>
                            <button className={`rounded-md py-2 font-mono mt-[60px] w-full ${Subscription.subType === 'demo' ? 'bg-prime text-white' : 'bg-white text-prime border border-dashed border-prime'}`} onClick={() => { gotoContact() }}>
                                {Subscription.subType === 'demo' ? "CURRNT PLAN" : "CONTACT SALES"}
                            </button>
                        </div>
                        <div className='col-span-1 border border-gray-200 rounded-md p-4'>
                            <h2 className='text-lg font-medium text-prime'>Yearly Plan</h2>
                            <h3 className='text-5xl font-medium mt-[60px]'>₹ 9999</h3>
                            <hr className='mt-2' />
                            <ul className='mt-2'>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> All The Services</li>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> Free Menu Updation</li>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> All Reporting, layouts and customization option</li>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> All 90 Days Post Cancellation Databack</li>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> Renew at only ₹ 7599</li>
                            </ul>
                            <button className={`rounded-md py-2 font-mono mt-[60px] w-full ${Subscription.subType === 'monthly' ? 'bg-prime text-white' : 'bg-white text-prime border border-dashed border-prime'}`} onClick={() => { gotoContact() }}>
                                {Subscription.subType === 'monthly' ? "CURRNT PLAN" : "CONTACT SALES"}
                            </button>
                        </div>
                        <div className='col-span-1 border border-gray-200 rounded-md p-4'>
                            <h2 className='text-lg font-medium text-prime'>Monthly Plan</h2>
                            <h3 className='text-5xl font-medium mt-[60px]'>₹ 999</h3>
                            <hr className='mt-2' />
                            <ul className='mt-2'>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> All The Services</li>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> Free Menu Updation</li>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> All Reporting, layouts and customization option</li>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> All 90 Days Post Cancellation Databack</li>
                                <li className='text-sm font-medium text-ascent flex'><Check size={18} className='mr-2' /> Renew at only ₹ 799</li>
                            </ul>
                            <button className={`rounded-md py-2 font-mono mt-[60px] w-full ${Subscription.subType === 'yearly' ? 'bg-prime text-white' : 'bg-white text-prime border border-dashed border-prime'}`} onClick={() => { gotoContact() }}>
                                {Subscription.subType === 'yearly' ? "CURRNT PLAN" : "CONTACT SALES"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
