import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceMenu {
    lookupService = new APIServiceLookUp();

    async getAllMenu(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}menu/getAllMenu`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateMenuById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}menu/updateMenu`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async deleteMenuById(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}menu/deleteMenuById`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setMenu(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}menu/setMenu`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}