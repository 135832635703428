import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import { FileText, AreaChart, SquareGanttChart, FilePieChart, Bed } from 'lucide-react';

import PanelDev from '../panels/dev/PanelDev';

import PanelReports from 'panels/reports/PanelReports';
import PanelReportItem from 'panels/reports/PanelReportItem';
import PanelReportOrder from 'panels/reports/PanelReportOrder';
import PanelReportInvoice from 'panels/reports/PanelReportInvoice';
import PanelReportBooking from 'panels/reports/PanelReportBooking';

export default function LayoutReports() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-2xl font-medium px-2 mx-2 py-1 my-2 rounded">Reports</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start">
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'reports' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('reports') }}>
                                <AreaChart size={20} className='mr-2' /> Overview
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'reportItem' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('reportItem') }}>
                                <SquareGanttChart size={20} className='mr-2' /> Item wise report
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'reportOrder' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('reportOrder') }}>
                                <FilePieChart size={20} className='mr-2' /> Order wise report
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'reportInvoice' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('reportInvoice') }}>
                                <FileText size={20} className='mr-2' /> Invoice wise Report
                            </li>
                            {/* <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'reportBooking' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('reportBooking') }}>
                                <Bed size={20} className='mr-2' /> Booking wise Report
                            </li> */}
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'reports':
                                return (<PanelReports onToggle={toggleMenu} />);
                            case 'reportItem':
                                return (<PanelReportItem onToggle={toggleMenu} />);
                            case 'reportOrder':
                                return (<PanelReportOrder onToggle={toggleMenu} />);
                            case 'reportInvoice':
                                return (<PanelReportInvoice onToggle={toggleMenu} />);
                            case 'reportBooking':
                                return (<PanelReportBooking onToggle={toggleMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
