import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { AnimatePresence, motion } from "framer-motion";

import { FileText, AreaChart, SquareGanttChart, Package } from 'lucide-react';

import PanelDev from '../panels/dev/PanelDev';
import PanelInventory from 'panels/inventory/PanelInventory';
import PanelInventoryList from 'panels/inventory/PanelInventoryList';
import PanelInventoryAdjAdd from 'panels/inventory/PanelInventoryAdjAdd';
import PanelRaww from 'panels/inventory/PanelRaww';
import PanelRawwAdd from 'panels/inventory/PanelRawwAdd';
import PanelBom from 'panels/inventory/PanelBom';
import PanelBomAdd from 'panels/inventory/PanelBomAdd';

export default function LayoutInventory() {

    const [isOpen, setIsOpen] = useState(true);

    const { page } = useParams();

    const navigate = useNavigate();

    const itemVariants = {
        closed: { opacity: 0 },
        open: { opacity: 1 }
    };

    const changeNav = (link) => {
        navigate(`/home/${link}`);
        setIsOpen(true);
    }

    const toggleMenu = () => {
        setIsOpen(!isOpen);
    }

    const closeMenu = () => {
        setIsOpen(false);
    }

    const openMenu = () => {
        setIsOpen(true);
    }

    return (
        <>
            <AnimatePresence>
                {
                    isOpen && <motion.div initial={{ width: 0 }} animate={{ width: '16rem' }} exit={{ width: 0, transition: { delay: 0.2, duration: 0.3 } }} className="w-56 lg:w-64 h-screen bg-slate-100">
                        <motion.h1 initial="closed" animate="open" exit="closed" variants={itemVariants} className="text-2xl font-medium px-2 mx-2 py-1 my-2 rounded">Inventory</motion.h1>
                        <motion.ul initial="closed" animate="open" exit="closed" variants={itemVariants} className="w-64 flex flex-col items-start">
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'inventory' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('inventory') }}>
                                <AreaChart size={20} className='mr-2' /> Overview
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'inventoryList' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('inventoryList') }}>
                                <SquareGanttChart size={20} className='mr-2' /> Inventory Addjustment
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'raww' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('raww') }}>
                                <Package size={20} className='mr-2' /> Raww Material
                            </li>
                            <li className={`w-56 cursor-pointer text-base px-1 mx-4 py-1 my-1 hover:bg-gray-200 rounded flex items-center ${page === 'bom' ? 'text-prime font-medium' : 'text-black'}`} onClick={() => { changeNav('bom') }}>
                                <FileText size={20} className='mr-2' /> BOM List
                            </li>
                        </motion.ul>
                    </motion.div>
                }
            </AnimatePresence>
            <div className='h-screen w-full'>
                {
                    (() => {
                        switch (page) {
                            case 'inventory':
                                return (<PanelInventory onToggle={toggleMenu} onClose={closeMenu} onOpen={openMenu} />);
                            case 'inventoryList':
                                return (<PanelInventoryList onToggle={toggleMenu} onClose={closeMenu} onOpen={openMenu} />);
                            case 'inventoryAdd':
                                return (<PanelInventoryAdjAdd onToggle={toggleMenu} onClose={closeMenu} onOpen={openMenu} />);
                            case 'raww':
                                return (<PanelRaww onToggle={toggleMenu} onClose={closeMenu} onOpen={openMenu} />);
                            case 'rawwAdd':
                                return (<PanelRawwAdd onToggle={toggleMenu} onClose={closeMenu} onOpen={openMenu} />);
                            case 'bom':
                                return (<PanelBom onToggle={toggleMenu} onClose={closeMenu} onOpen={openMenu} />);
                            case 'bomAdd':
                                return (<PanelBomAdd onToggle={toggleMenu} onClose={closeMenu} onOpen={openMenu} />);
                            default:
                                return (<PanelDev />);
                        }
                    })()
                }
            </div>
        </>
    )
}
