import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Calendar } from "@/components/ui/calendar";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";

import { cn } from "@/lib/utils";

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../core/config/atoms';

import { motion } from "framer-motion";
import Moment from 'moment';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

import { Calendar as CalendarIcon } from "lucide-react";

import TopBar from '../../core/widgets/ui/TopBar';

// import ServiceStaff from '../../services/serviceStaff';

export default function PanelReportOrder(props) {

    const [token] = useRecoilState(tokenAtom);

    const [users, setUsers] = useState([]);

    const [filter, setFilter] = useState({});

    const [date, setDate] = useState({
        from: Moment().startOf('month').toDate(),
        to: Moment().toDate(),
    });

    const [tab, setTab] = useState('all');
    const [query, setQuery] = useState('');

    const [pageSize, setPageSize] = useState(10);
    const [pageCurrent, setPageCurrent] = useState(0);
    const [pageTotal, setPageTotal] = useState(0);

    const [isDate, setIsDate] = useState(false);

    const navigate = useNavigate();
    // const staffService = new ServiceStaff();

    const addStaff = () => {
        navigate(`/home/staffAdd`);
    }

    const changeTab = (val) => {
        setTab(val);
        setQuery('');
        setDate({
            from: Moment().startOf('year').toDate(),
            to: Moment().toDate(),
        });
        switch (val) {
            case "all":
                setFilter({});
                break;
            case "active":
                setFilter({ 'status': 'active' });
                break;
            case "banned":
                setFilter({ 'status': 'banned' });
                break;
            default:
                break;
        }
    }

    const handleOnChange = (e) => {
        var { value, name } = e.target;
        switch (name) {
            case 'pageSize':
                setPageSize(value);
                break;
            case 'search':
                setQuery(value);
                break;
        }
    }

    const onDateChange = (event) => {
        setIsDate(true);
        setDate(event);
    }

    const downloadFile = ({ data, fileName, fileType }) => {
        const blob = new Blob([data], { type: fileType })
        const a = document.createElement('a')
        a.download = fileName
        a.href = window.URL.createObjectURL(blob)
        const clickEvt = new MouseEvent('click', {
            view: window,
            bubbles: true,
            cancelable: true,
        })
        a.dispatchEvent(clickEvt)
        a.remove()
    }

    const getExportItems = () => {
        let items = [];
        users.forEach((item) => {
            items.push({
                uid: item.uid,
                fname: item.fname,
                mname: item.mname,
                lname: item.lname,
                email: item.email,
                phone: item.phone,
                role: item.role,
                status: item.status,
                createdAt: item.createdAt,
            });
        });
        return items;
    }

    const exportToExcel = () => {
        const worksheet = XLSX.utils.json_to_sheet(getExportItems());
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, "staff");
        XLSX.writeFile(workbook, "staff.xlsx");
    }

    const exportToCsv = () => {
        let headers = ['uid,fname,mname,lname,email,phone,role,status'];
        let usersCsv = users.reduce((acc, user) => {
            const { uid, fname, mname, lname, email, phone, role, status } = user
            acc.push([uid, fname, mname, lname, email, phone, role, status].join(','))
            return acc
        }, []);
        downloadFile({
            data: [...headers, ...usersCsv].join('\n'),
            fileName: 'staff.csv',
            fileType: 'text/csv',
        });
    }

    const updateStatus = (id, status) => {
        // let body = {
        //     uid: id,
        //     data: {
        //         status: status
        //     }
        // }
        // staffService.updateStaffById(body, token).then((res) => {
        //     if (res['status']) {
        //         loadData();
        //     } else {
        //         toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        //     }
        // });
    }

    const deleteStaff = (id) => {
        // let body = {
        //     uid: id,
        // }
        // staffService.deleteStaffById(body, token).then((res) => {
        //     if (res['status']) {
        //         loadData();
        //     } else {
        //         toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        //     }
        // });
    }

    const pageChange = (pageValue) => {
        (async function () {
            // let body = {
            //     "filter": filter,
            //     "page": pageValue,
            //     "size": parseInt(pageSize),
            //     "searchQuery": query,
            //     "startDate": isDate ? date.from.toISOString() : "",
            //     "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            // };
            // var res = await staffService.getAllEmps(body, token);
            // if (res['status']) {
            //     setPageCurrent(res.data.currentPage);
            //     setPageTotal(res.data.totalPage);
            //     setUsers(res.data.data);
            // } else {
            //     toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            // }
        })();
    }

    const loadData = () => {
        (async function () {
            // let body = {
            //     "filter": filter,
            //     "page": 1,
            //     "size": parseInt(pageSize),
            //     "searchQuery": query,
            //     "startDate": isDate ? date.from.toISOString() : "",
            //     "endDate": isDate ? Moment(date.to).add(1, 'day').toDate().toISOString() : ""
            // };
            // var res = await staffService.getAllStaff(body, token);
            // if (res['status']) {
            //     setPageCurrent(res.data.currentPage);
            //     setPageTotal(res.data.totalPage);
            //     setUsers(res.data.data);
            // } else {
            //     toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            // }
        })();
    }

    const nextPage = () => {
        if (pageCurrent < pageTotal) {
            pageChange(pageCurrent + 1);
        }
    }

    const prevPage = () => {
        if (pageCurrent > 1) {
            pageChange(pageCurrent - 1);
        }
    }

    useEffect(() => {
        loadData();
    }, []);

    useEffect(() => {
        loadData();
    }, [pageSize, query, date, filter]);

    return (
        <ScrollArea className="w-full h-full py-3 px-3">
            <div className="grid grid-cols-12 grid-gap-2">
                <div className="col-span-12 mt-2">
                    <div class="intro-y flex items-center justify-between h-10 border-b pb-4">
                        <h2 class="text-2xl font-medium font-serif truncate ml-2 text-ascent">
                            <motion.i whileHover={{ scale: 1.2 }} class="las la-bars cursor-pointer" onClick={() => { props.onToggle(); }}></motion.i> Order wise reports
                        </h2>
                        <TopBar />
                    </div>
                    <div class="w-full mt-4 flex justify-between items-center">
                        <div className='h-12 bg-gray-100 rounded'>
                            <ul className="intro-y flex text-sm font-sans">
                                <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'all' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('all') }}>All</button></li>
                                <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'active' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('active') }}>Active</button></li>
                                <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'paid' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('paid') }}>Paid</button></li>
                                <li className={`px-3 h-8 m-2 rounded flex items-center justify-center ${tab === 'cancel' ? 'text-red-600 font-medium bg-white shadow' : 'text-asent font-medium'}`}><button className='' onClick={() => { changeTab('cancel') }}>Cancelled</button></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-span-12 mt-4 px-2">
                <div className="w-full shadow-md rounded-md border">
                    <table className="min-w-full divide-y divide-gray-200 px-4">
                        <thead className="bg-gray-50">
                            <tr className=''>
                                <th scope="col" className="border-b border-gray-200" colSpan={8}>
                                    <div className='w-full grid grid-cols-12 gap-2'>
                                        <form className="items-center py-2 col-span-11 px-2 grid grid-cols-12 gap-2">
                                            <div className='col-span-3 flex'>
                                                <Popover>
                                                    <PopoverTrigger asChild>
                                                        <Button id="date" variant={"outline"}
                                                            className={cn(
                                                                "w-[300px] justify-start text-left font-normal",
                                                                !date && "text-muted-foreground"
                                                            )}
                                                        >
                                                            <CalendarIcon className="mr-2 h-4 w-4" />
                                                            {date?.from ? (
                                                                date.to ? (
                                                                    <>
                                                                        {Moment(date.from).format('MMM DD, yyyy')} -{" "}
                                                                        {Moment(date.to).format('MMM DD, yyyy')}
                                                                    </>
                                                                ) : (
                                                                    Moment(date.from).format('MMM DD, yyyy')
                                                                )
                                                            ) : (
                                                                <span>Pick a date</span>
                                                            )}
                                                        </Button>
                                                    </PopoverTrigger>
                                                    <PopoverContent className="w-auto p-0" align="start">
                                                        <Calendar
                                                            initialFocus
                                                            mode="range"
                                                            defaultMonth={date?.from}
                                                            selected={date}
                                                            onSelect={onDateChange}
                                                            numberOfMonths={2}
                                                        />
                                                    </PopoverContent>
                                                </Popover>
                                            </div>
                                            <div className='w-full flex col-span-9 items-center'>
                                                <div className='w-[80px]'>
                                                    <p>Search :</p>
                                                </div>
                                                <input type="text" value={query} onChange={handleOnChange} name='search' placeholder="Search by name and email." className="w-full h-10 px-4 font-sans text-sm outline-none rounded border" />
                                            </div>
                                        </form>
                                        <div className='col-span-1 flex items-center'>
                                            <DropdownMenu>
                                                <DropdownMenuTrigger>
                                                    <button className="w-full py-1 px-2 h-10 rounded-md bg-prime hover:bg-primeLight text-white mr-4"><i className="las la-file-download mr-2"></i> Export </button>
                                                </DropdownMenuTrigger>
                                                <DropdownMenuContent>
                                                    <div
                                                        className='bg-white shadow-md rounded-md absolute' style={{ top: '0px', left: '-64px' }}>
                                                        <div>
                                                            <ul className='flex flex-col items-start p-2'>
                                                                <DropdownMenuItem>
                                                                    <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                        exportToExcel();
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .xlsx</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                                <DropdownMenuItem>
                                                                    <li className="w-40 cursor-pointer p-2 rounded-md" onClick={() => {
                                                                        exportToCsv();
                                                                    }}>
                                                                        <div className='flex items-center text-base'>
                                                                            <p className="text-sm font-serif ml-2 font-normal">Export to .csv</p>
                                                                        </div>
                                                                    </li>
                                                                </DropdownMenuItem>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </DropdownMenuContent>
                                            </DropdownMenu>
                                        </div>
                                    </div>
                                </th>
                            </tr>
                            <tr>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    ORDER ID
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    ORDER Type
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Order Items
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Order Price
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Status
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Payment Status
                                </th>
                                <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                    Created At
                                </th>
                                <th scope="col" className="px-2 py-3"></th>
                            </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                            {
                                // users.length !== 0 && users.map((user) => {
                                //     return <tr className="hover:bg-gray-100">
                                //         <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                //             <div className="text-sm font-bold text-gray-900">
                                //                 <div className='flex items-center text-prime'>
                                //                     {user.uid}
                                //                 </div>
                                //             </div>
                                //         </td>
                                //         <td className="px-6 py-4 whitespace-nowrap">
                                //             <div className="text-sm font-bold text-gray-900 uppercase">
                                //                 {user.fname} {user.mname} {user.lname}
                                //             </div>
                                //         </td>
                                //         <td className="px-6 py-4 whitespace-nowrap">
                                //             <div className="text-sm font-bold text-gray-900">
                                //                 {user.email}
                                //             </div>
                                //         </td>
                                //         <td className="px-6 py-4 whitespace-nowrap">
                                //             <div className="text-sm text-prime">
                                //                 {user.role}
                                //             </div>
                                //         </td>
                                //         <td className="px-6 py-4 whitespace-nowrap">
                                //             <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${user.status == 'active' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
                                //                 {user.status.toUpperCase()}
                                //             </span>
                                //         </td>
                                //         <td>
                                //             {
                                //                 (user.role != "SADMIN" && user.role != "ADMIN") && <DropdownMenu>
                                //                     <DropdownMenuTrigger>
                                //                         <motion.i whileHover={{ scale: 1.1 }} class="las la-ellipsis-v text-lg"></motion.i>
                                //                     </DropdownMenuTrigger>
                                //                     <DropdownMenuContent>
                                //                         <div>
                                //                             <ul className='flex flex-col items-start'>
                                //                                 {
                                //                                     user.status == 'active' && <DropdownMenuItem>
                                //                                         <li className='w-32 cursor-pointer rounded-md' onClick={() => {
                                //                                             updateStatus(user._id, 'banned');
                                //                                         }}>
                                //                                             <div className='flex items-center text-base'>
                                //                                                 <i className="las la-ban text-lg"></i>
                                //                                                 <p className='text-sm font-serif ml-2'>Block</p>
                                //                                             </div>
                                //                                         </li>
                                //                                     </DropdownMenuItem>
                                //                                 }
                                //                                 {
                                //                                     user.status == 'banned' && <DropdownMenuItem>
                                //                                         <li className='w-32 cursor-pointer rounded-md' onClick={() => {
                                //                                             updateStatus(user._id, 'active');
                                //                                         }}>
                                //                                             <div className='flex items-center text-base'>
                                //                                                 <i className="las la-chevron-circle-up text-lg"></i>
                                //                                                 <p className='text-sm font-serif ml-2'>Active</p>
                                //                                             </div>
                                //                                         </li>
                                //                                     </DropdownMenuItem>
                                //                                 }
                                //                                 {
                                //                                     user.status == 'banned' && <DropdownMenuItem>
                                //                                         <li className='w-32 cursor-pointer rounded-md' onClick={() => {
                                //                                             deleteStaff(user._id);
                                //                                         }}>
                                //                                             <div className='flex items-center text-base'>
                                //                                                 <i className="las la-trash text-lg"></i>
                                //                                                 <p className='text-sm font-serif ml-2'>Delete</p>
                                //                                             </div>
                                //                                         </li>
                                //                                     </DropdownMenuItem>
                                //                                 }
                                //                             </ul>
                                //                         </div>
                                //                     </DropdownMenuContent>
                                //                 </DropdownMenu>
                                //             }
                                //         </td>
                                //     </tr>
                                // })
                            }
                            {
                                users.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                    <td className="px-6 py-4 whitespace-nowrap" colSpan="8">
                                        <div className="text-sm text-red-600 text-center">
                                            No data found.
                                        </div>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                    <div className='flex py-2 px-4 items-center bg-blue-50'>
                        <p className='text-sm font-serif'>Show rows per page</p>
                        <select className='text-sm ml-2 bg-gray-100' name="pageSize" onChange={handleOnChange} value={pageSize}>
                            <option value={10}>10</option>
                            <option value={20}>20</option>
                            <option value={40}>40</option>
                            <option value={50}>50</option>
                        </select>
                        <div className='flex-grow'></div>
                        <p className='text-sm font-serif mr-2'>Page {pageCurrent} of {pageTotal}</p>
                        <div onClick={() => { prevPage() }}><i className={`las la-angle-left w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ${pageCurrent <= 1 ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                        <div onClick={() => { nextPage() }}><i className={`las la-angle-right w-6 h-6 flex items-center justify-center rounded-full hover:bg-gray-200 ml-2 text-black ${pageCurrent === pageTotal ? 'text-gray-400 cursor-not-allowed' : 'text-black cursor-pointer'}`}></i></div>
                    </div>
                </div>
            </div>
        </ScrollArea>
    )
}
