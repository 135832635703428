import React, { useState, useEffect } from 'react';

export default function ComponentTimer({ startTime, isOn, isElapsed }) {

    const [elapsedTime, setElapsedTime] = useState(0);

    useEffect(() => {
        const intervalId = setInterval(() => {
            const currentTime = new Date();
            const elapsedSeconds = Math.floor((currentTime - new Date(startTime)) / 1000);
            if (isElapsed) {
                const totalSeconds = 3600 - elapsedSeconds;
                setElapsedTime(totalSeconds);
            } else {
                setElapsedTime(elapsedSeconds);
            }
        }, 1000);
        return () => clearInterval(intervalId);
    }, [startTime]);

    const getHours = () => {
        return Math.floor(elapsedTime / 3600);
    }

    const getMins = () => {
        return Math.floor((elapsedTime % 3600) / 60);
    }

    const getSecs = () => {
        return elapsedTime % 60;
    }

    return (
        <p className='text-base font-medium font-serif ml-1'>
            {isOn ? `${getHours().toString().padStart(2, '0')}:${getMins().toString().padStart(2, '0')}:${getSecs().toString().padStart(2, '0')}` : 'NA'}
        </p>
    );
}
