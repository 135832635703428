import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServicePrinter {
    lookupService = new APIServiceLookUp();

    async setPrinter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}printer/setPrinter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllPrinters(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}printer/getAllPrinter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllPrinterByType(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}printer/getAllPrinterByType`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updatePrinter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}printer/updatePrinter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async delPrinter(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}printer/delPrinter`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}