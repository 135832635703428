import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceOrder {
    lookupService = new APIServiceLookUp();

    async setOrder(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/setOrder`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async setOrderWithRef(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/setOrderWithRef`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateOrderWithRef(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/updateOrderWithRef`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllOrders(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getAllOrders`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllOrdersT(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getAllOrdersT`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getOrderByUnit(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/getOrderByUnit`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async shiftUnit(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/shiftUnit`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async updateOrder(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}order/updateOrder`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}