import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../../core/config/atoms';

import Skeleton from 'react-loading-skeleton';
import Moment from 'moment';

// import ServiceOverview from '../../../../services/serviceOverview';

export default function ComponentExpiryOrderList() {

    const [token] = useRecoilState(tokenAtom);

    const [orders, setOrders] = useState([]);

    const [loading, setLoading] = useState(true);

    // const overviewService = new ServiceOverview();

    const loadData = () => {
        setLoading(true);
        (async function () {
            // const res = await overviewService.getUpcomingOrders({}, token);
            // setOrders(res.data);
            setLoading(false);
        })();
    }

    const getCustomerName = (ord) => {
        let name = "";
        if (ord.lead) {
            name = ord.lead.name;
        } else {
            name = ord.customer.name;
        }
        return name;
    }

    const getCustomerEmail = (ord) => {
        let email = "";
        if (ord.lead) {
            email = ord.lead.email;
        } else {
            email = ord.customer.email;
        }
        return email;
    }

    const getOrderTotal = (ord) => {
        let total = 0;
        ord.items.forEach((item) => {
            total = total + parseFloat(item.rate);
        });
        return total;
    }

    const getStatusColor = (status) => {
        let color = "";
        switch (status) {
            case 'active':
                color = 'bg-blue-100 text-blue-800';
                break;
            case 'paid':
            case 'shipped':
                color = 'bg-yellow-100 text-yellow-800';
                break;
            case 'delivered':
                color = 'bg-green-100 text-green-800';
                break;
            case 'cancel':
                color = 'bg-red-100 text-red-800';
                break;
        }
        return color;
    }

    const getTagColor = (tag) => {
        let color = "";
        switch (tag) {
            case 'NA':
                color = 'bg-gray-200 text-gray-800';
                break;
            case 'dash':
                color = 'bg-blue-200 text-blue-800';
                break;
            case 'web':
                color = 'bg-green-200 text-green-800';
                break;
        }
        return color;
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <div className="col-span-12 cursor-pointer">
            <div className="shadow bg-white rounded-lg p-4">
                <h3 className='text-lg font-sans font-medium'>Upcoming Orders Due | Top 10</h3>
                <hr className='my-2' />
                {loading && <Skeleton count={10} />}
                <table className="min-w-full divide-y divide-gray-200 px-4">
                    <thead className="bg-gray-50">
                        <tr>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                # ORDER NUMBER
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Customer Details
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Products Details
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Status
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Origin
                            </th>
                            <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider cursor-pointer">
                                Created At
                            </th>
                        </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                        {
                            orders.length !== 0 && orders.map((order, index) => {
                                return <tr className="hover:bg-gray-100" key={index}>
                                    <td className="px-6 py-4 whitespace-nowrap cursor-pointer">
                                        <div className="text-sm font-bold text-gray-900">
                                            <div className='flex items-center text-blue-600'>
                                                {order.OID}
                                            </div>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="font-bold">
                                            <span className='text-sm text-gray-900 uppercase'>{getCustomerName(order)}</span><br />
                                            <span className='text-xs text-gray-600'>Email: <b>{getCustomerEmail(order)}</b></span>
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="font-bold text-gray-900 text-sm">
                                            Order Total: <span className='text-green-600'>$ {getOrderTotal(order).toFixed(2)}</span><br />
                                            Products x {order.items.length}
                                        </div>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getStatusColor(order.status)}`}>
                                            {order.status.toUpperCase()}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <span className={`px-2 inline-flex text-xs leading-5 font-semibold rounded-full uppercase ${getTagColor(order.tag)}`}>
                                            {order.tag.toUpperCase()}
                                        </span>
                                    </td>
                                    <td className="px-6 py-4 whitespace-nowrap">
                                        <div className="text-sm text-blue-600">
                                            {order.createdAt}
                                        </div>
                                    </td>
                                </tr>
                            })
                        }
                        {
                            orders.length === 0 && <tr className="cursor-pointer hover:bg-gray-100">
                                <td className="px-6 py-4 whitespace-nowrap" colSpan="6">
                                    <div className="text-sm text-blue-600 text-center">
                                        No data found.
                                    </div>
                                </td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
