import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import { useRecoilState } from 'recoil';
import { modeAtom, authAtom, companyAtom, userRoleAtom, subAtom, tokenAtom, userAtom } from './core/config/atoms';

import { ToastContainer } from 'react-toastify';
import { toast } from 'react-toastify';

import PageAuth from './pages/PageAuth';
import PageHome from './pages/PageHome';
import Page404 from './pages/Page404';

import ServiceStaff from 'services/serviceStaff';
import ServiceUtility from 'services/serviceUtility';

export default function App() {

    const [modeStatus] = useRecoilState(modeAtom);
    const [authStatus, setAuthStatus] = useRecoilState(authAtom);

    const [, setUserRole] = useRecoilState(userRoleAtom);
    const [, setSub] = useRecoilState(subAtom);

    const [comp, setComp] = useRecoilState(companyAtom);
    const [userData, setUserData] = useRecoilState(userAtom);
    const [token, setToken] = useRecoilState(tokenAtom);

    const staffService = new ServiceStaff();
    const utilService = new ServiceUtility();

    const checkUser = () => {
        var body = {
            'uid': userData._id,
            'cid': comp._id,
        };
        staffService.checkStaff(body, token).then((res) => {
            if (res.status) {
                setUserData(res.data.user);
                setUserRole(res.data.user.role);
                setComp(res.data.company);
                setSub(res.data.sub);
                utilService.getTokenById({ id: res.data.user._id }, '').then((res) => {
                    setToken(res.data.token);
                });
            } else {
                setAuthStatus('default');
                setUserData({});
                setUserRole("");
                setComp({});
                setSub({});
                window.location.replace('/');
                toast.error('Error: ' + res.msg, { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        });
    }

    useEffect(() => {
        const bodyEl = document.querySelector("body");
        modeStatus ? bodyEl.classList.add("dark") : bodyEl.classList.remove("dark");
        if (authStatus !== "default") {
            checkUser();
        }
    }, [modeStatus, authStatus]);

    return (
        <>
            <ToastContainer position="top-right" autoClose={2000} hideProgressBar={false} newestOnTop={false} rtl={false} theme="light" />
            <Router>
                <Routes>
                    <Route exact path="/" element={<PageAuth />} />
                    {authStatus && <Route exact path="/home/:page/*" element={<PageHome />} />}
                    {authStatus && <Route exact path="/home/:page/:id" element={<PageHome />} />}
                    <Route path="*" element={<Page404 />} />
                </Routes>
            </Router>
        </>
    )
}