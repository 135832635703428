import APIServiceLookUp from './serviceAPI';
import { API_URL } from './models/AppConstent';

export default class ServiceInventory {
    lookupService = new APIServiceLookUp();

    async setInventory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}inventory/setInventory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

    async getAllInventory(body, token) {
        var serviceType = "POST";
        var url = `${API_URL}inventory/getAllInventory`;
        var apiService = this.lookupService.getAPIService(serviceType);
        return await apiService.doCall(url, body, token);
    }

}