import React, { useState, useEffect } from 'react';

import { useRecoilState } from 'recoil';
import { tokenAtom } from '../../../config/atoms';

import Lottie from 'lottie-react';
import { Dialog } from '@headlessui/react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { toast } from 'react-toastify';

import animBtnLoading from '../../../../assets/anim/anim-btnLoading.json';

import ServicePrinter from 'services/servicePrinter';

export default function DigPrinterForm(props) {

    const [token] = useRecoilState(tokenAtom);

    const [isEdit, setIsEdit] = useState(false);

    const types = [
        { lable: 'KOT+Bill', value: 'KB' },
        { lable: 'Bill', value: 'B' },
        { lable: 'KOT', value: 'K' },
    ]

    const [printers, setPrinters] = useState([]);

    const [submit, setSubmit] = useState(false);

    const printerService = new ServicePrinter();

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        type: Yup.string().required('This information is required'),
        size: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, setValues, handleChange, handleSubmit, handleReset, resetForm } = useFormik({
        initialValues: {
            'name': '',
            'type': '',
            'size': '',
        },
        validationSchema: formVSchema,
        onSubmit: values => {
            setSubmit(true);
            if (!isEdit) {
                var body = {
                    "name": values.name,
                    "type": values.type,
                    "size": values.size,
                };
                printerService.setPrinter(body, token).then((d) => {
                    resetForm();
                    setSubmit(false);
                    if (d.status) {
                        toast.success("Printer added.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error("Printer add error.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    props.onClose();
                });
            } else {
                let body = {
                    uid: props.record._id,
                    data: {
                        "name": values.name,
                        "type": values.type,
                        "size": values.size,
                    }
                }
                printerService.updatePrinter(body, token).then((res) => {
                    resetForm();
                    setSubmit(false);
                    if (res['status']) {
                        toast.success("Printer updated.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    } else {
                        toast.error("Printer update error.", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                    props.onClose();
                });
            }
        }
    });

    const loadData = () => {
        (async function () {
            const availablePrinters = await window.electronAPI.getPrinters();
            let tempList = [];
            availablePrinters.map((item) => {
                tempList.push({ lable: item, value: item });
            });
            setPrinters(tempList);
            if (Object.keys(props.record).length !== 0) {
                setValues({ ...values, "name": props.record.name, "type": props.record.type, "size": props.record.size });
                setIsEdit(true);
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <Dialog open={props.status} onClose={() => { props.onClose(); }} className='overflow-y-auto overflow-x-hidden z-[100] w-full h-modal md:h-full fixed top-0 left-0 bg-black bg-opacity-70'>
            <div className="relative w-full max-w-2xl h-full md:h-auto mx-auto bg-white shadow-lg rounded-md mt-10 p-4">
                <div className='flex items-center justify-between'>
                    <h3 className="text-xl font-semibold text-gray-900">
                        Printer Form &nbsp;
                    </h3>
                    <span className='bg-gray-200 hover:bg-gray-100 w-8 h-8 rounded-full cursor-pointer flex items-center justify-center' onClick={() => {
                        props.onClose();
                    }}>
                        <i className="las la-times text-sm text-black"></i>
                    </span>
                </div>
                <hr className='mt-2' />
                <form className='mt-4' onSubmit={handleSubmit} onReset={handleReset} autoComplete="off">
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Select Printer<sup className="text-red-600">*</sup></label>
                        <select type="text" name="name" id="name" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Table 101 / Room 101" onChange={handleChange} value={values.unit}>
                            <option value="">-- Select Printer --</option>
                            {
                                printers.map((item) => {
                                    return <option value={item.value} selected={item.value == values.name}>{item.lable}</option>
                                })
                            }
                        </select>
                        {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Select Printer Type<sup className="text-red-600">*</sup></label>
                        <select type="text" name="type" id="type" className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5" placeholder="i.e. Table 101 / Room 101" onChange={handleChange} value={values.type}>
                            <option value="">-- Select Type --</option>
                            {
                                types.map((item) => {
                                    return <option value={item.value} selected={item.value == values.type}>{item.lable}</option>
                                })
                            }
                        </select>
                        {(errors.type && touched.type) && <p className='text-xs text-red-400 mt-1'>{errors.type}</p>}
                    </div>
                    <div className="mb-6">
                        <label className="block mb-2 text-sm font-medium text-gray-600">Printer Paper Size (mm)<sup className="text-red-600">*</sup></label>
                        <input type="number" name="size" id="size" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. 10mm" step="any" value={values.size} />
                        {(errors.size && touched.size) && <p className='text-xs text-red-400 mt-1'>{errors.size}</p>}
                    </div>
                    <div className='flex items-end'>
                        <button type="submit" className="text-white bg-red-600 hover:bg-red-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center" disabled={submit}>
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && (isEdit ? "UPDATE" : "ADD")}
                        </button>
                    </div>
                </form>
            </div>
        </Dialog>
    )
}
