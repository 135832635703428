import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { ScrollArea } from "@/components/ui/scroll-area";

import { Trash2 } from "lucide-react";

import { useRecoilState } from 'recoil';
import { tokenAtom, userAtom } from '../../core/config/atoms';

import { useFormik } from 'formik';
import * as Yup from 'yup';
import Lottie from 'lottie-react';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';

import ServiceMenu from '../../services/serviceMenu';

import animBtnLoading from '../../assets/anim/anim-btnLoading.json';

export default function PanelSettingsMenuAdd(props) {

    const [token] = useRecoilState(tokenAtom);
    const [userdata] = useRecoilState(userAtom);

    const [productFile, setSelectedFile] = useState(null);

    const [catOptions, setCatOptions] = useState([]);
    const [variants, setVariants] = useState([]);
    const [addons, setAddons] = useState([]);

    const [title, setTitle] = useState('');
    const [umrp, setURMP] = useState('');
    const [utax, setUTAX] = useState('');
    const [makeTime, setMakeTime] = useState('');

    const [addTitle, setAddTitle] = useState('');
    const [addPrice, setAddPrice] = useState('');

    const [submit, setSubmit] = useState(false);
    let [isDoc, setIsDoc] = useState(false);

    const navigate = useNavigate();
    const menuService = new ServiceMenu();

    const menuTypes = [
        { value: 'VEG', label: 'Veg' },
        { value: 'NONVEG', label: 'Non-Veg' },
        { value: 'EGG', label: 'Egg' },
    ];

    const favTypes = [
        { value: 'TRUE', label: 'Favorite' },
        { value: 'FALSE', label: 'Regular ' },
    ];

    const goBack = () => {
        props.onToggle();
        navigate(`/home/menu`);
    }

    const formVSchema = Yup.object().shape({
        name: Yup.string().required('This information is required'),
        category: Yup.string().required('This information is required'),
        shortCode: Yup.string().required('This information is required'),
        isFav: Yup.string().required('This information is required'),
        type: Yup.string().required('This information is required'),
    });

    const { values, errors, touched, handleChange, handleSubmit, handleReset, setValues } = useFormik({
        initialValues: {
            name: '',
            category: '',
            shortCode: '',
            variant: '',
            addOns: '',
            isFav: '',
            type: '',
        },
        enableReinitialze: true,
        validationSchema: formVSchema,
        onSubmit: values => {
            if (variants.length === 0) {
                toast.error('Please add at least one variant.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            } else {
                setSubmit(true);
                let body = {
                    "name": values.name,
                    "category": values.category,
                    "shortCode": values.shortCode,
                    "variant": variants,
                    "addOns": addons,
                    "isFav": values.isFav === "TRUE",
                    "type": values.type,
                    "uid": userdata._id,
                    "status": "active"
                }
                menuService.setMenu(body, token).then((res) => {
                    setSubmit(false);
                    if (res.status) {
                        toast.success("New menu added!", { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                        goBack();
                    } else {
                        toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
                    }
                });
            }
        },
    });

    const handleChangeForm = (e) => {
        const { value, name } = e.target;
        switch (name) {
            case "title":
                setTitle(value);
                break;
            case "umrp":
                setURMP(value);
                break;
            case "tax":
                setUTAX(value);
                break;
            case "utime":
                setMakeTime(value);
                break;
            case "aTitle":
                setAddTitle(value);
                break;
            case "aPrice":
                setAddPrice(value);
                break;
        }
    }

    const addVarents = () => {
        if (title !== "" && umrp !== "" && utax !== "" && makeTime !== "") {
            let tempList = [...variants];
            tempList.push({
                "title": title,
                "price": umrp,
                "tax": utax,
                "makeTime": makeTime,
                "profile_media": "",
                "status": "active",
                "type": values.type
            });
            setVariants(tempList);
            setTitle('');
            setURMP('');
            setUTAX('');
            setMakeTime('');
        } else {
            toast.error('Please fill all the information.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const removeVarents = (index) => {
        let tempList = [...variants];
        tempList.splice(index, 1);
        setVariants(tempList);
    }

    const addAddOn = () => {
        if (addTitle !== "" && addPrice !== "") {
            let tempList = [...addons];
            tempList.push({
                'title': addTitle,
                'price': addPrice,
            });
            setAddons(tempList);
            setAddTitle('');
            setAddPrice('');
        } else {
            toast.error('Please fill all the information.', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
        }
    }

    const removeAddOn = (index) => {
        let tempList = [...addons];
        tempList.splice(index, 1);
        setAddons(tempList);
    }

    const handleViewDoc = (type) => {
        setIsDoc(true);
    }

    const loadData = () => {
        (async function () {
            let body = {
                "filter": {},
                "page": 1,
                "size": 300,
                "searchQuery": "",
                "startDate": "",
                "endDate": ""
            };
            var res = await menuService.getAllMenu(body, token);
            if (res['status']) {
                let tempCat = [];
                res.data.data.forEach(element => {
                    if (!tempCat.some(item => item.value === element.category)) {
                        tempCat.push({ value: element.category, label: element.category });
                    }
                });
                setCatOptions(tempCat);
            } else {
                toast.error('Server error please try again', { position: "top-right", autoClose: 2000, hideProgressBar: false, closeOnClick: true, progress: undefined, theme: "light" });
            }
        })();
    }

    useEffect(() => {
        loadData();
    }, []);

    return (
        <ScrollArea className="w-full h-screen p-2 flex flex-col overflow-auto">
            <div className="p-3">
                <form onSubmit={handleSubmit}>
                    <div className="intro-y flex items-center cursor-pointer" onClick={() => { goBack() }}>
                        <i className="las la-long-arrow-alt-left text-xl"></i>
                        <h4 className="text-xs text-black font-sans font-medium ml-2">Back to all menus</h4>
                    </div>
                    <div className="intro-y mt-4">
                        <h3 className="text-2xl font-sans font-bold text-gray-800">Add Menu Item</h3>
                        <p className="mt-1 text-sm text-gray-600 mb-6">
                            Please enter corret details and information related to menu.
                        </p>
                        <hr />
                    </div>
                    <div className="mt-4">
                        <div className="grid grid-cols-12 gap-6">
                            <div className="intro-y mt-5 md:mt-0 md:col-span-12">
                                <div className="shadow">
                                    <div className="px-4 py-5 bg-white sm:p-6 rounded-lg">
                                        <h3 className="col-span-6 text-lg font-medium leading-6 text-gray-900 mb-2">Menu Information</h3>
                                        <div className="grid grid-cols-12 gap-6">
                                            <div className='col-span-6 grid grid-cols-6 gap-3'>

                                                <div className="col-span-3 my-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Menu item name<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="name" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="item name" value={values.name} />
                                                    {(errors.name && touched.name) && <p className='text-xs text-red-400 mt-1'>{errors.name}</p>}
                                                </div>

                                                <div className="col-span-3 my-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Menu item short code<sup className="text-red-600">*</sup></label>
                                                    <input type="text" id="shortCode" onChange={handleChange} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-gray-100 text-sm border-gray-400 rounded-md outline-none" placeholder="i.e. 001" value={values.shortCode} />
                                                    {(errors.shortCode && touched.shortCode) && <p className='text-xs text-red-400 mt-1'>{errors.shortCode}</p>}
                                                </div>

                                                <div className="col-span-3 my-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Menu item category<sup className="text-red-600">*</sup></label>
                                                    <CreatableSelect isClearable options={catOptions} onChange={(e) => {
                                                        if (e) {
                                                            setValues({ ...values, 'category': e.value });
                                                        } else {
                                                            setValues({ ...values, 'category': '' });
                                                        }
                                                    }} name="category" id="category" className="mt-1 shadow-inner" />
                                                    {(errors.category && touched.category) && <p className='text-xs text-red-400 mt-1'>{errors.category}</p>}
                                                </div>

                                                <div className="col-span-3 my-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Is Favorite<sup className="text-red-600">*</sup></label>
                                                    <select id="isFav" className="appearance-none w-full py-1 px-2 bg-white mt-2 border-gray-400 rounded outline-none border" onChange={handleChange} value={values.isFav}>
                                                        <option value="" disabled="" selected="selected">Select</option>
                                                        {
                                                            favTypes.length !== 0 && favTypes.map((item) => {
                                                                return <option value={item.value} selected={values.isFav == item.value}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {(errors.isFav && touched.isFav) && <p className='text-xs text-red-400 mt-1'>{errors.isFav}</p>}
                                                </div>

                                                <div className="col-span-3 my-2">
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Item Type<sup className="text-red-600">*</sup></label>
                                                    <select id="type" className="appearance-none w-full py-1 px-2 bg-white mt-2 border-gray-400 rounded outline-none border" onChange={handleChange} value={values.type}>
                                                        <option value="" disabled="" selected="selected">Select</option>
                                                        {
                                                            menuTypes.length !== 0 && menuTypes.map((item) => {
                                                                return <option value={item.value} selected={values.type == item.value}>{item.label}</option>
                                                            })
                                                        }
                                                    </select>
                                                    {(errors.type && touched.type) && <p className='text-xs text-red-400 mt-1'>{errors.type}</p>}
                                                </div>

                                                <h3 className="col-span-6 text-lg font-medium leading-6 text-gray-900 mb-2 mt-2">Menu Variant</h3>
                                                {
                                                    variants.length !== 0 && <div className="col-span-6">
                                                        {
                                                            variants.map((vari, index) => {
                                                                return <div className='w-full py-2 my-1 flex items-center justify-between' key={index}>
                                                                    <div>
                                                                        <h1 className='text-lg font-medium'>{vari.title}</h1>
                                                                        <p className='text-sm'>Price: ₹ <span className='font-medium'>{vari.price}</span> Tax: <span className='font-medium'>{vari.tax} (%)</span></p>
                                                                        <p className='text-sm'>Make Time: <b>{vari.makeTime} Min</b></p>
                                                                    </div>
                                                                    <div>
                                                                        <button type="button" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight mt-2" onClick={() => {
                                                                            removeVarents(index);
                                                                        }}><Trash2 size={18} /></button>
                                                                    </div>
                                                                </div>
                                                            })
                                                        }
                                                    </div>
                                                }
                                                <div className="col-span-6 grid grid-cols-6 gap-3">
                                                    <div className="col-span-6">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Title:<sup className="text-red-600">*</sup></label>
                                                        <input type="text" name="title" onChange={handleChangeForm} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. Title" value={title} />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">MRP:<sup className="text-red-600">*</sup>&nbsp;(₹)</label>
                                                        <input type="number" name="umrp" onChange={handleChangeForm} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. 100" step="any" value={umrp} />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">GST:<sup className="text-red-600">*</sup>&nbsp;(%)</label>
                                                        <input type="number" name="tax" onChange={handleChangeForm} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. 5" step="any" value={utax} />
                                                    </div>
                                                    <div className="col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Make Time:<sup className="text-red-600">*</sup>&nbsp;(min)</label>
                                                        <input type="number" name="utime" onChange={handleChangeForm} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. 10" step="any" value={makeTime} />
                                                    </div>

                                                    <button type="button" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight mt-2" onClick={() => {
                                                        addVarents();
                                                    }}>ADD</button>
                                                </div>
                                            </div>
                                            <div className='col-span-6 flex flex-col'>
                                                <div className='w-full my-2'>
                                                    <label className="block text-xs font-medium text-gray-700 font-mono">Product Image</label>
                                                    {
                                                        productFile === null && <div className="mt-1 h-[160px] flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md">
                                                            <div className="space-y-1 text-center">
                                                                <svg className="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
                                                                    <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                                                                </svg>
                                                                <div className="flex text-sm text-gray-600">
                                                                    <label className="relative cursor-pointer bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500">
                                                                        <span>Upload a file</span>
                                                                        <input type="file" className="sr-only" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                                                    </label>
                                                                    <p className="pl-1">or drag and drop</p>
                                                                </div>
                                                                <p className="text-xs text-gray-500">
                                                                    PNG, JPG up to 1MB
                                                                </p>
                                                            </div>
                                                        </div>
                                                    }
                                                    {
                                                        productFile !== null && <div className="pl-3 pr-4 py-3 flex items-center justify-between text-sm bg-gray-100 rounded border mt-2">
                                                            <div className="w-0 flex-1 flex items-center">
                                                                <i className="las la-link text-gray-400 text-lg"></i>
                                                                <span className="ml-2 flex-1 w-0 truncate">
                                                                    {productFile.name}
                                                                </span>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-blue-600 font-mono" onClick={() => handleViewDoc('pan')}>
                                                                    <span>View</span>
                                                                </label>
                                                            </div>
                                                            <div className="ml-4 flex-shrink-0">
                                                                <label className="relative cursor-pointer rounded-md font-medium text-indigo-600 font-mono">
                                                                    <span>Replace</span>
                                                                    <input type="file" className="sr-only" onChange={(e) => setSelectedFile(e.target.files[0])} />
                                                                </label>
                                                            </div>
                                                        </div>
                                                    }
                                                </div>

                                                <div className='w-full grid grid-cols-6 gap-3'>
                                                    <h3 className="col-span-6 text-lg font-medium leading-6 text-gray-900 mb-2 mt-2">Menu Add-ons</h3>

                                                    {
                                                        addons.length !== 0 && <div className="col-span-6">
                                                            {
                                                                addons.map((addo, index) => {
                                                                    return <div className='w-full py-2 my-1 flex items-center justify-between' key={index}>
                                                                        <div>
                                                                            <h1 className='text-lg font-medium'>{addo.title}</h1>
                                                                            <p className='text-sm'>Price: ₹ <span className='font-medium'>{addo.price}</span></p>
                                                                        </div>
                                                                        <div>
                                                                            <button type="button" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight mt-2" onClick={() => {
                                                                                removeAddOn(index);
                                                                            }}><Trash2 size={18} /></button>
                                                                        </div>
                                                                    </div>
                                                                })
                                                            }
                                                        </div>
                                                    }

                                                    <div className="col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Title:<sup className="text-red-600">*</sup></label>
                                                        <input type="text" name="aTitle" onChange={handleChangeForm} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. addon" value={addTitle} />
                                                    </div>

                                                    <div className="col-span-2">
                                                        <label className="block text-xs font-medium text-gray-700 font-mono">Price:<sup className="text-red-600">*</sup>&nbsp;(₹)</label>
                                                        <input type="number" name="aPrice" onChange={handleChangeForm} autoComplete="off" className="mt-1 p-2 block w-full h-9 shadow-inner bg-white text-sm border-gray-400 rounded outline-none border" placeholder="i.e. 50" step="any" value={addPrice} />
                                                    </div>

                                                    <button type="button" disabled={submit} className="h-[38px] w-[80px] justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight mt-4" onClick={() => {
                                                        addAddOn();
                                                    }}>ADD</button>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="intro-y px-2 py-3 bg-gray-100 text-right">
                        <button type="submit" disabled={submit} className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-prime hover:bg-primeLight">
                            {submit && <Lottie animationData={animBtnLoading} className="w-8 h-8" loop={true} />}
                            {!submit && "ADD MENU"}
                        </button>
                    </div>
                </form>
            </div >
        </ScrollArea>
    )
}
